import { LoadingState } from "@features/utils/store/loading-state-atom";
import { useCallback } from "react";
import { useRecoilState } from "recoil";
import { ProductsApiClient } from "../api-client/api-client";
import { ProductAtom } from "./store";
import { useGlobalEffect } from "@features/utils/hooks/use-global-effect";
import toast from "react-hot-toast";
import { getRouteProduct } from "../utils";
import { useParams } from "react-router-dom";
import { Book, ConditionAchat } from "../types";

export const useProduct = (ean: string, specific?: string) => {
  const [product, setProduct] = useRecoilState(ProductAtom(ean));
  const { type } = useParams<{ type: string }>();
  const [loading, setLoading] = useRecoilState(
    LoadingState(["useProduct+" + ean, true])
  );

  const refresh = useCallback(async () => {
    setLoading(true);
    try {
      const result = await ProductsApiClient.getProduct(
        ean,
        getRouteProduct(type || ""),
        specific
      );
      if (result.codeTypeProd === "LIVR") {
        let r = await ProductsApiClient.getProductConditionAchat(
          ean,
          getRouteProduct(type || "")
        );
        (result as Book).listConditionAchat = r.items as ConditionAchat[];
      }
      setProduct(result);
    } catch (err) {
      toast.error("Article non trouvé.");
    }
    setLoading(false);
  }, [ean, specific, setProduct, setLoading, type]);

  useGlobalEffect(
    "useProduct+" + ean + "+" + specific || "",
    async () => {
      await refresh();
    },
    []
  );

  //Ajouter les debounce quand on aura un backend pour ça
  const getFieldSuggestions = async (query: string) => {
    if (query.trim()) {
      return await ProductsApiClient.getFieldSuggestion(query);
    } else {
      return [];
    }
  };

  const referenceProduct = async () => {
    await ProductsApiClient.referenceProduct(ean);
  };

  return {
    product,
    type,
    loading,
    refresh,
    getFieldSuggestions,
    referenceProduct,
  };
};
