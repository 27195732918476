import { Modal, ModalContent } from "@atoms/modal/modal";
import { Base, BaseBold, Info, Subtitle } from "@atoms/text";
import { ProductTypeOrder } from "@features/customer/type";
import { useShopLocations } from "@features/general/auth/state/use-store-location";
import {
  LieuStockInfo,
  ProductType,
  ProductTypeShort,
} from "@features/products/types";
import { sortByKey } from "@features/utils/divers";
import { Column } from "@molecules/table/table";
import { TableGridSwitch } from "@molecules/table/table-grid-switch";
import { atom, useRecoilState } from "recoil";

export const LieuStockAtom = atom({
  key: "LieuStockAtom",
  default: {
    open: false,
    product: null as ProductType | ProductTypeShort | ProductTypeOrder | null,
    lieuStockList: [] as LieuStockInfo[],
  },
});

export const LieuStockModal = () => {
  const { current } = useShopLocations();
  const [lieuStockModal, setLieuStockModal] = useRecoilState(LieuStockAtom);
  const columns: Column<LieuStockInfo>[] = [
    {
      title: "Point de vente",
      render: (el) => (
        <Base className="grow text-rightmr-8"> {el.codeLieu} </Base>
      ),
      className: (el) =>
        el.codeLieu === current?.codeLieu ? "!bg-slate-200" : "",
    },
    {
      title: `Stock (${lieuStockModal.lieuStockList.reduce(
        (total, item) => total + item.qteStock,
        0
      )})`,
      orderable: true,
      render: (el) => <Base className="grow text-right"> {el.qteStock} </Base>,
      className: (el) =>
        el.codeLieu === current?.codeLieu ? "!bg-slate-200" : "",
    },
    {
      title: `Qte prep retour (${lieuStockModal.lieuStockList.reduce(
        (total, item) => total + item.qtePrepRet,
        0
      )})`,
      orderable: true,
      render: (el) => (
        <Base className="grow text-right"> {el.qtePrepRet} </Base>
      ),
      className: (el) =>
        el.codeLieu === current?.codeLieu ? "!bg-slate-200" : "",
    },
    {
      title: `Qte dispo vente (${lieuStockModal.lieuStockList.reduce(
        (total, item) => total + item.qteDispoVente,
        0
      )})`,
      orderable: true,
      render: (el) => (
        <Base className="grow text-right"> {el.qteDispoVente} </Base>
      ),
      className: (el) =>
        el.codeLieu === current?.codeLieu ? "!bg-slate-200" : "",
    },
    {
      title: `Qte dispo vente future (${lieuStockModal.lieuStockList.reduce(
        (total, item) => total + item.qteDispoVenteFutur,
        0
      )})`,
      orderable: true,
      render: (el) => (
        <Base className="grow text-right"> {el.qteDispoVenteFutur} </Base>
      ),
      className: (el) =>
        el.codeLieu === current?.codeLieu ? "!bg-slate-200" : "",
    },
    {
      title: `Qte en préparation de commande (${lieuStockModal.lieuStockList.reduce(
        (total, item) => total + item.qtePrepCde,
        0
      )})`,
      orderable: true,
      render: (el) => (
        <Base className="grow text-right"> {el.qtePrepCde} </Base>
      ),
      className: (el) =>
        el.codeLieu === current?.codeLieu ? "!bg-slate-200" : "",
    },
  ];

  return (
    <Modal
      className="sm:max-w-[900px]"
      open={lieuStockModal.open}
      onClose={() =>
        setLieuStockModal({ open: false, product: null, lieuStockList: [] })
      }
    >
      <ModalContent title="Stocks points de vente">
        <div className="w-full flex flex-col gap-4">
          <div className="flex flex-col">
            {" "}
            <Subtitle>{lieuStockModal.product?.designation}</Subtitle>
            <Subtitle>{lieuStockModal.product?.id}</Subtitle>
          </div>

          {window.innerWidth < 400 && (
            <div className="border-2 bg-slate-300">
              <StockCard
                {...{
                  codeLieu: "Cumul des points de vente",
                  qteStock: lieuStockModal.lieuStockList.reduce(
                    (total, item) => total + item.qteStock,
                    0
                  ),
                  qteDispoVente: lieuStockModal.lieuStockList.reduce(
                    (total, item) => total + item.qteDispoVente,
                    0
                  ),
                  qteDispoVenteFutur: lieuStockModal.lieuStockList.reduce(
                    (total, item) => total + item.qteDispoVenteFutur,
                    0
                  ),
                  qtePrepCde: lieuStockModal.lieuStockList.reduce(
                    (total, item) => total + item.qtePrepCde,
                    0
                  ),
                  qtePrepRet: lieuStockModal.lieuStockList.reduce(
                    (total, item) => total + item.qtePrepRet,
                    0
                  ),
                }}
              />
            </div>
          )}

          <TableGridSwitch
            gridMode={false}
            showPagination={false}
            renderGrid={(shopStock) => <StockCard {...shopStock} />}
            tableColumns={columns}
            data={lieuStockModal.lieuStockList}
            customChangeOrder={async ({ page, perPage, order, orderBy }) => {
              if (order && orderBy && lieuStockModal.lieuStockList[0]) {
                const targetedKey = [
                  "codeLieu",
                  "qteStock",
                  "qtePrepRet",
                  "qteDispoVente",
                  "qteDispoVenteFutur",
                  "qtePrepCde",
                ][orderBy];
                const tempArray = [...lieuStockModal.lieuStockList];
                setLieuStockModal({
                  ...lieuStockModal,
                  lieuStockList: sortByKey(tempArray, targetedKey, order),
                });
              }
            }}
          />
        </div>
      </ModalContent>
    </Modal>
  );
};

export const StockCard = (shopStock: LieuStockInfo) => {
  const { current } = useShopLocations();
  return (
    <div
      className={`flex flex-col p-2 ${
        shopStock.codeLieu === current?.codeLieu ? "bg-slate-200" : ""
      } `}
    >
      <BaseBold className="">{shopStock.codeLieu}</BaseBold>
      <Info>Stock: {shopStock.qteStock}</Info>
      <Info>Qte prep retour: {shopStock.qtePrepRet}</Info>
      <Info>Qte dispo vente: {shopStock.qteDispoVente}</Info>
      <Info>Qte dispo vente future: {shopStock.qteDispoVenteFutur}</Info>
      <Info>Qte en préparation de commande: {shopStock.qtePrepCde}</Info>
    </div>
  );
};
