import { Button } from "@atoms/button/button";
import SelectMultiple from "@atoms/input/input-select-multiple";
import { Modal, ModalContent } from "@atoms/modal/modal";
import { BaseSmall, Info, InfoSmallBold } from "@atoms/text";
import { useReception } from "@features/reception/state/use-reception";
import { MissingArticle } from "@features/reception/types";
import { PrinterIcon } from "@heroicons/react/outline";
import { Table } from "@molecules/table";
import { Column } from "@molecules/table/table";
import { atom, useRecoilState } from "recoil";
import { CommandFournModalAtom } from "../commande-fournisseur-modal";
import { useEffect, useState } from "react";

type MissingArticlesModalType = {
  open: boolean;
  missingArticles: MissingArticle[];
};

export const MissingArticlesModalAtom = atom<MissingArticlesModalType>({
  key: "MissingArticlesModal",
  default: { open: false, missingArticles: [] },
});

export const MissingArticlesModal = () => {
  const [missingArticlesModal, setMissingArticlesModal] = useRecoilState(
    MissingArticlesModalAtom
  );
  const { selectedReception, updateMissingArticles } = useReception();
  const [blurOn, setBlurOn] = useState(true);
  const [commandFourndModal, setCommandFournModal] = useRecoilState(
    CommandFournModalAtom
  );

  //   const barCode: Column<MissingArticle> = {
  //     title: "Code Barre",
  //     orderable: false,
  //     render: (p) => (
  //       <div className="w-full flex justify-start ">
  //         <Barcode value={p.eaN13} height={20} width={1.3} />
  //       </div>
  //     ),
  //   };

  useEffect(() => {
    setBlurOn(!commandFourndModal.open);
  }, [commandFourndModal]);

  const numCde: Column<MissingArticle> = {
    title: "Num. commande",
    render: (el) => (
      <BaseSmall className="w-full text-center">{el.numCde}</BaseSmall>
    ), //Temp
  };

  const articles: Column<MissingArticle> = {
    title: "Articles",
    orderable: true,
    render: (p) => (
      <div className="grow flex flex-col items-start verflow-hidden border-r border-r-1 mr-1">
        <InfoSmallBold
          className="overflow-hidden cursor-text text-ellipsis print:whitespace-normal"
          data-tooltip={p.designation}
        >
          {p.designation}
        </InfoSmallBold>
        <Info>{p.designation}</Info>
        <Info>ID BD: {p.idBD}</Info>
        <Info>Réferénce: {p.referProd}</Info>
      </div>
    ),
  };

  const qteStock: Column<MissingArticle> = {
    title: "Qte stock",
    render: (el) => (
      <BaseSmall className="w-full text-center">{el.qteStock}</BaseSmall>
    ),
  };

  const qteCde: Column<MissingArticle> = {
    title: "Qte commandée",
    render: (el) => (
      <BaseSmall className="w-full text-center">{el.qteCde}</BaseSmall>
    ), // À renseigner
  };

  const qteRecu: Column<MissingArticle> = {
    title: "Qte reçue",
    render: (el) => (
      <BaseSmall className="w-full text-center">{el.qteRecu}</BaseSmall>
    ),
  };

  const motif: Column<MissingArticle> = {
    title: "Motif",
    render: (el) => (
      <div className="w-full flex justify-center">
        <SelectMultiple
          className="max-w-36"
          value={[]}
          onChange={(e) => {}}
          onSearch={(e) => {}}
          options={[]}
          selectionLimit={1}
        />
      </div>
    ),
  };

  const action: Column<MissingArticle> = {
    title: "Action",
    render: (el) => (
      <div className="w-full flex justify-center">
        <SelectMultiple
          className="max-w-36"
          value={[]}
          onChange={(e) => {
            // let tempTab = [...missingArticlesModal.missingArticles];
            // const article = {
            //   ...tempTab.find((article) => article.numLigne === el.numLigne),
            // };
            // for (const cle of Object.keys(article)) {
            //   if (Array.isArray(article[cle])) {
            //     article[cle] = newValue[cle][0];
            //   }
            //   if (temp[cle] === undefined) {
            //     delete temp[cle];
            //   }
            // }
            // setReturnObj;
          }}
          onSearch={(e) => {}}
          options={[
            { label: "", value: "" },
            { label: "Noter", value: "NOTE" },
            { label: "Re-commander", value: "RCDE" },
            { label: "Annuler", value: "ANNU" },
          ]}
          selectionLimit={1}
        />
      </div>
    ),
  };
  const nbCmdClient: Column<MissingArticle> = {
    title: "Nb commandes client",
    render: (el) => (
      <div className="w-full flex justify-center">
        <Button
          theme="secondary"
          onClick={() => {
            setCommandFournModal({
              open: true,
              listCde: selectedReception.listReceiptCdeFourn,
            });
          }}
        >
          8 Cde client
        </Button>
      </div>
    ),
  };

  const infosFournisseur: Column<MissingArticle> = {
    title: "Infos fournisseur",
    render: (el) => (
      <BaseSmall className="w-full text-center">"unknown"</BaseSmall>
    ),
  };

  const handleClose = () => {
    setMissingArticlesModal({ open: false, missingArticles: [] });
  };

  return (
    <Modal
      style={{ maxWidth: "1600px" }}
      open={missingArticlesModal.open}
      onClose={() => handleClose()}
      blurActivated={blurOn}
    >
      <ModalContent title={"Produits manquants"}>
        <div className="grow">
          <Table
            // cellClassName={() =>
            //   "border-2 whitespace-nowrap max-w-max text-overflow-ellipsis "
            // }
            emptyTabText={"Aucun articles manquant"}
            columns={[
              numCde,
              articles,
              qteStock,
              qteCde,
              qteRecu,
              motif,
              action,
              nbCmdClient,
              infosFournisseur,
              //barCode,
            ]}
            data={missingArticlesModal.missingArticles}
          />
        </div>
        <div className="flex flex-row gap-4 mt-4">
          <Button
            className="shrink-0"
            onClick={() => {}}
            theme="secondary"
            data-tooltip="Imprimer"
            icon={({ className }) => <PrinterIcon className={className} />}
          >
            Imprimer
          </Button>
          <Button
            onClick={async () => {
              await updateMissingArticles(missingArticlesModal.missingArticles);
            }}
          >
            Sauvegarder
          </Button>
          <Button onClick={() => handleClose()}>Valider les choix</Button>
        </div>
      </ModalContent>
    </Modal>
  );
};
