import { useScan } from "@atoms/input-scan/use-scan";
import { ScanTriggerIconDomId } from "./_layout/scan";
import { QrcodeIcon, XIcon } from "@heroicons/react/outline";
import { createPortal } from "react-dom";

export const ScanIconTriggerWidget = () => {
  const { scanOn, cameraAvailable, openScan, closeScan } = useScan();
  const el = document.getElementById(ScanTriggerIconDomId);

  return (
    <>
      {el &&
        cameraAvailable &&
        createPortal(
          <div
            style={{ zIndex: 1000000 }}
            data-tooltip={scanOn ? "" : "Open barcode scanner"}
            className="fixed right-4 bottom-4 p-2 rounded-full bg-white shadow-lg cursor-pointer text-blue-600 hover:text-blue-800 hover:bg-blue-100"
            onClick={() => {
              if (!scanOn) {
                openScan();
              } else {
                closeScan();
              }
            }}
          >
            {!scanOn && <QrcodeIcon className="h-8 w-8" />}
            {!!scanOn && <XIcon className="h-8 w-8" />}
          </div>,
          el
        )}
    </>
  );
};
