import { InputLabel } from "@atoms/input/decoration-label";
import Link from "@atoms/link";
import { Base, BaseSmall, Info } from "@atoms/text";
import { formatTime } from "@features/utils/format/dates";
import { formatAmount } from "@features/utils/format/strings";
import { memo } from "react";
import { SearchFormFieldType } from "./types";
import { useSetRecoilState } from "recoil";
import { HtmlFormModal, HtmlFormModalText } from "./htmlFormModal";

export const FormReadonly = memo(
  (
    props: Omit<SearchFormFieldType, "key"> & {
      size: "md" | "lg";
      value: string | boolean | number | Date | string[];
      values: any;
    }
  ) => {
    const setHtmlModal = useSetRecoilState(HtmlFormModal);
    const setHtmlText = useSetRecoilState(HtmlFormModalText);
    if (props.type === "custom") {
      return (
        <>
          {(props as any).node({
            value: props.value,
          })}
        </>
      );
    }

    if (!props.value && !props.alwaysVisible) return <></>;

    if (
      (props.value === undefined || props.value === null) &&
      props.alwaysVisible
    )
      return (
        <InputLabel
          className="select-text"
          labelClassName="opacity-50"
          label={props.label || ""}
          input={<Info className="whitespace-nowrap">-</Info>}
        />
      );

    return (
      <InputLabel
        // className={`select-text ${
        //   props.type === "number" ||
        //   props.format === "price" ||
        //   props.format === "percentage"
        //     ? "text-right"
        //     : ""
        // }`}
        className={"select-text"}
        labelClassName="opacity-50"
        label={props.label || ""}
        input={
          <>
            {(!props.type ||
              props.type === "text" ||
              props.type === "scan" ||
              props.type === "number") && (
              <BaseSmall
                className={props.type === "number" ? "text-right" : ""}
              >
                {props.render?.(props.value, props.values) ||
                  (props.value as string)}
              </BaseSmall>
            )}
            {props.type === "date" && (
              <Base className="whitespace-nowrap">
                {props.render?.(props.value, props.values) ||
                  formatTime(props.value as string, "fr-FR", {
                    keepTime:
                      new Date(props.value as string).getSeconds() === 0
                        ? false
                        : true,
                    keepSeconds: false,
                    keepDate: true,
                  })}
              </Base>
            )}
            {props.type === "modal" && (
              <BaseSmall>
                <Link
                  className="cursor-pointer flex items-center underline"
                  onClick={() => {
                    props.onClick &&
                      props.onClick({ readonly: true, values: props.values });
                  }}
                >
                  {props.render?.(props.value, props.values) ||
                    (props.value as string)}
                </Link>
              </BaseSmall>
            )}
            {props.type === "html" && (
              <div
                className="text-sm font-normal text-slate-800 dark:text-white"
                onClick={() => {
                  setHtmlText(props.value.toString());
                  setHtmlModal(true);
                }}
                dangerouslySetInnerHTML={
                  props.value.toString().length > 350
                    ? {
                        __html:
                          props.value.toString().slice(0, 350) +
                          '<span style="color: #94a3b8;" class="hover-truc"> ...Lire la suite</span>',
                      }
                    : { __html: props.value.toString() }
                }
              />
            )}

            {/* {props.type === "html" && (
              <div
                onClick={() => {
                  setHtmlText(props.value.toString());
                  setHtmlModal(true);
                }}
                dangerouslySetInnerHTML={{
                  __html: props.value.toString() + "...lire la suite",
                }}
              />
            )} */}
            {(props.type === "boolean" || props.type === "select_boolean") && (
              <Base>
                {props.render?.(props.value, props.values) || props.value
                  ? "OUI"
                  : "NON"}
              </Base>
            )}
            {props.type === "formatted" && props.format === "price" && (
              <Base className="grow text-right">
                {props.render?.(props.value, props.values) ||
                  formatAmount(
                    parseFloat(props.value as string),
                    props.currencySymb,
                    props.currencyNbDecim
                  )}
              </Base>
            )}
            {props.type === "formatted" && props.format === "percentage" && (
              <Base className="grow text-right">
                {props.render?.(props.value, props.values) ||
                  parseFloat(props.value as string).toFixed(2)}{" "}
                %
              </Base>
            )}

            {props.type === "formatted" && props.format === "mail" && (
              <Link href={`mailto:${props.value}`}>
                {props.render?.(props.value, props.values) ||
                  (props.value as string)}{" "}
              </Link>
            )}

            {props.type === "formatted" && props.format === "phone" && (
              <Link href={`tel:${props.value}`}>
                {props.render?.(props.value, props.values) ||
                  (props.value as string)}{" "}
              </Link>
            )}
            {(props.type === "multiselect" ||
              props.type === "select" ||
              props.type === "searchselect") && (
              <BaseSmall>
                {props.render?.(props.value, props.values) ||
                  (
                    (typeof props.options === "object" ? props.options : []) ||
                    []
                  ).find((v) => v.value === props.value)?.label ||
                  (props.value as string)}
              </BaseSmall>
            )}
          </>
        }
      />
    );
  }
);
