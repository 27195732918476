import { Button } from "@atoms/button/button";
import { Frame } from "@atoms/layout/frame";
import { Modal, ModalContent } from "@atoms/modal/modal";
import { Base, Info } from "@atoms/text";
import { Form } from "@molecules/form";
import { SearchFormFieldType } from "@molecules/form/types";
import { t } from "i18next";
import { useState } from "react";
import { atom, useRecoilState } from "recoil";

export const RapatriementModalAtom = atom({
  key: "RapatriementModalAtom",
  default: false,
});

const rapatriementConfigFields: SearchFormFieldType[] = [
  {
    key: "txImplicite",
    label: t("reception.tauxImplicite"),
    type: "select",
  },
  {
    key: "tpAchat",
    label: t("reception.typeAchat"),
    type: "select",
  },
  {
    key: "affctCommentaires",
    label: t("reception.affecterLescommentaires"),
    type: "select",
  },
  {
    key: "visuAffectation",
    label: t("reception.Visualiser les affectations"),
    type: "select",
  },
  {
    key: "modeAffectation",
    label: t("reception.Mode affectation"),
    type: "select",
  },
  {
    key: "etiqCommandes",
    label: t("reception.Etiqueter les commandes"),
    type: "select",
  },

  {
    key: "bordereau",
    label: t("reception.Bordereau"),
    type: "text",
    alwaysVisible: true,
  },
];

type RapatriementConfigType = {
  txImplicite: "OUI";
  tpAchat: "OUI";
  affectCommentaires: "OUI";
  visuaAffection: "OUI";
  modeAffection: "OUI";
  etiquCommandes: "OUI";
  borderau: "";
};

export const RapatriementModal = () => {
  const [value, setValue] = useState({
    txImplicite: "OUI",
    tpAchat: "OUI",
    affectCommentaires: "OUI",
    visuaAffection: "OUI",
    modeAffection: "OUI",
    etiquCommandes: "OUI",
    borderau: "",
  });
  const [rapatriementModal, setRapatriementModal] = useRecoilState(
    RapatriementModalAtom
  );

  const allColis = [
    { libele: "", nbRef: 0, nbItem: 0 },
    { libele: "", nbRef: 0, nbItem: 0 },
    { libele: "", nbRef: 0, nbItem: 0 },
  ];
  return (
    <Modal
      className="sm:max-w-[900px]"
      open={rapatriementModal}
      onClose={() => setRapatriementModal(false)}
    >
      <ModalContent title="Rapatriement">
        <div className="flex flex-col items-center gap-2">
          <Base>Avex 00000123</Base>
          <ul>
            {allColis.map((colis, key) => (
              <li key={key}>
                <Info>
                  Colis{" "}
                  {`${colis.libele} (${colis.nbRef} réf, ${colis.nbItem})`}
                </Info>
              </li>
            ))}
          </ul>
          <Frame className="w-full">
            <Form
              fields={rapatriementConfigFields}
              value={value}
              onChange={(newValue) =>
                setValue(newValue as RapatriementConfigType)
              }
            ></Form>
          </Frame>
          <Base>Voulez vous rapatrier les lignes ? </Base>
          <div className="flex gap-2">
            <Button>Non</Button>
            <Button>Oui</Button>
          </div>
        </div>
      </ModalContent>
    </Modal>
  );
};
