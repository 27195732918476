import { fetchServer } from "@features/utils/fetch-server";
import { Taxe } from "../types";

export class CommonApiClient {
  static getCountries = async (clientId: string) => {
    const data = await fetchServer(`/country`, {
      method: "GET",
    });
    const result = await data.json();
    if (data.status !== 200) {
      throw new Error(result.title);
    }
    return result;
  };

  static getTaxes = async () => {
    const data = await fetchServer(`/tax`, {
      method: "GET",
    });
    const result = await data.json();
    if (data.status !== 200) {
      throw new Error(result.title);
    }
    return result as Taxe[];
  };
}
