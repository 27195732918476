import { Button } from "@atoms/button/button";
import { useScan } from "@atoms/input-scan/use-scan";
import { Input } from "@atoms/input/input-text";
import { InputWithSuggestions } from "@atoms/input/input-with-suggestion";
import { Frame } from "@atoms/layout/frame";
import { Page } from "@atoms/layout/page";
import { Info, Subtitle } from "@atoms/text";
import { useReception } from "@features/reception/state/use-reception";
import { ROUTES } from "@features/routes";
import { PlusIcon, ViewListIcon } from "@heroicons/react/outline";
import { Form } from "@molecules/form";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaArrowsRotate } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { useSetRecoilState } from "recoil";
import { CommandFournModalAtom } from "../receptions-modals/commande-fournisseur-modal";
import { UnknownArticlesModalAtom } from "../receptions-modals/unknown-articles-modal";
import { ReceptionUnit } from "@features/reception/types";
//Page sûrement temporaire
//Pourra être refactorisée plus tard

export const ReceptionHeaderPage = () => {
  const {
    selectedReception,
    createHeader,
    receiptScannedProduct,
    changeSelectedReception,
    rappatrierLines,
  } = useReception();
  const [inputValue, setInputValue] = useState("");
  //const [tauxList, setTauxList] = useState<Taux[]>([]);
  const setCommandFournModal = useSetRecoilState(CommandFournModalAtom);
  const setUnknownArticlesModal = useSetRecoilState(UnknownArticlesModalAtom);
  const { scanOn } = useScan();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const isBlValid = () => {
    if (!selectedReception.numBL || selectedReception.numBL.trim() === "") {
      toast.error("Il faut renseigner un numéro de BL !");
      setInputValue("");
      return false;
    }
    return true;
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      document.title = `Inférence - Creation Recep. ${selectedReception.codeFourn} - ${selectedReception.numRecep}`;
    }, 100);

    return () => {
      clearTimeout(timer);
    };
  }, [selectedReception]);

  return (
    <Page>
      <div className="flex flex-col gap-6">
        <div
          className="mx-auto w-full flex h-full w-full bg-red-400 "
          style={{ maxWidth: 540 }}
        >
          <InputWithSuggestions
            options={[]}
            inputMode={scanOn ? "none" : undefined}
            inputClassName="to-focus"
            autoFocus={true}
            placeholder="Scanner"
            onChange={(e) => setInputValue(e.target.value)}
            value={inputValue}
          />

          <Button
            className="shrink-0"
            shortcut={["enter"]}
            icon={(p) => <PlusIcon {...p} />}
            onClick={async () => {
              if (isBlValid()) {
                await createHeader(selectedReception);
                const res = await receiptScannedProduct(inputValue, true);
                if (res) {
                  navigate(
                    ROUTES.OngoingReception.replace(
                      /:numBL/,
                      selectedReception.numBL
                    ),
                    { state: { scannedProduct: res } }
                  );
                }
              }
            }}
          />
        </div>
        <div className="flex gap-4 justify-end mt-4">
          {" "}
          {selectedReception.listReceiptCdeFourn &&
            !(
              selectedReception.listReceiptCdeFourn.length === 1 &&
              selectedReception.listReceiptCdeFourn.find(
                (el) => el.numCde === null
              )
            ) && (
              <Button
                className="shrink-0"
                size="sm"
                onClick={(e) => {
                  e.stopPropagation();
                  setCommandFournModal({
                    open: true,
                    listCde: selectedReception.listReceiptCdeFourn,
                  });
                }}
                theme="secondary"
                icon={({ className }) => <ViewListIcon className={className} />}
              >
                {selectedReception.listReceiptCdeFourn.length > 1
                  ? `Commande fournisseurs associées (${selectedReception.listReceiptCdeFourn.length})`
                  : `Commande ${selectedReception.listReceiptCdeFourn[0].numCde} associée`}
              </Button>
            )}
          <Button
            size="sm"
            theme="secondary"
            icon={({ className }) => <FaArrowsRotate className={className} />}
            onClick={async () => {
              if (isBlValid()) {
                const resHeader = await createHeader(selectedReception);
                if (!resHeader) {
                  toast.error("Impossible de crée l'en tête de réception.");
                  return;
                }
                const resRappat = await rappatrierLines();
                if (!resRappat) {
                  toast.error("Impossible de rappatrier les lignes.");
                  return;
                }
                if (resRappat.length !== 0) {
                  setUnknownArticlesModal({
                    open: true,
                    unknownArticles: resRappat,
                  });
                }
                navigate(
                  ROUTES.OngoingReception.replace(
                    /:numBL/,
                    selectedReception.numBL
                  )
                );
              }
            }}
          >
            Rapatrier les lignes
          </Button>
        </div>
        <Frame className="mb-2">
          <div className="flex flex-row gap-3 items-center mb-4">
            <Subtitle className="">{`${selectedReception.codeFourn}`}</Subtitle>
            {selectedReception.numRecep && (
              <Subtitle>
                {t("Reception") + `${selectedReception.numRecep}`}
              </Subtitle>
            )}

            <div className="flex gap-3 text-center items-center">
              {selectedReception.typeRecep !== 0 ? (
                <>
                  <Subtitle>- BL </Subtitle>
                  <Input
                    size="sm"
                    className="max-w-48"
                    value={selectedReception.numBL}
                    onChange={(e) =>
                      changeSelectedReception({
                        ...selectedReception,
                        numBL: e.target.value,
                      })
                    }
                  />
                </>
              ) : (
                <Subtitle>{`- BL ${selectedReception.numBL}`} </Subtitle>
              )}
            </div>

            {selectedReception.numColis && (
              <>
                {selectedReception.orig !== "COLIS" && (
                  <Subtitle className="">
                    {`- Bordereau ${selectedReception.bord} `}
                  </Subtitle>
                )}
                {selectedReception.orig === "COLIS" && (
                  <Subtitle className="">
                    {`- Bordereau ${selectedReception.bord} `}
                  </Subtitle>
                )}
              </>
            )}
          </div>
          <Form
            readonly={false}
            onChange={(newValue) =>
              changeSelectedReception({ ...(newValue as ReceptionUnit) })
            }
            value={selectedReception}
            fields={[
              {
                key: "codeTypeTaux",
                label: t("reception.tauxImplicite"),
                type: "text",
                alwaysVisible: true,
                // options: tauxList.map((el) => {
                //   return { label: el.lib, value: el.code };
                // }),
              },
              {
                key: "codeTypeAchat",
                label: t("reception.typeAchat"),
                type: "select",
                alwaysVisible: true,
                options: [
                  {
                    label: "Achat ferme",
                    value: "0",
                  },
                  {
                    label: "Office",
                    value: "1",
                  },
                  {
                    label: "Dépôt permanent",
                    value: "2",
                  },
                  {
                    label: "Dépôt occasionnel",
                    value: "3",
                  },
                  {
                    label: "Droit de retour",
                    value: "4",
                  },
                ],
              },
              {
                key: "recepAffecCdeCli",
                label: "Affecter les commandes",
                type: "select",
                alwaysVisible: true,
                options: [
                  {
                    label: "Non",
                    value: "0",
                  },
                  { label: "Oui", value: "1" },

                  {
                    label: "Oui/Non",
                    value: "2",
                  },
                  {
                    label: "Non/Oui",
                    value: "3",
                  },

                  //N'existe apparament dans Inférence
                  // {
                  //   label: "Oui/PS",
                  //   value: "",
                  // },
                ],
              },
              {
                key: "modeAffecCdeCli",
                label: t("reception.Mode affectation"),
                type: "select",
                alwaysVisible: true,
                options: [
                  {
                    label: "Par ancienneté cde cli",
                    value: "0",
                  },
                  {
                    label: "Priorité aux cde cli associées",
                    value: "1",
                  },
                  {
                    label: "Exclusivement cde cli associées",
                    value: "2",
                  },
                ],
              },
              // {
              //   key: "dateMiniAvantRet",
              //   label: t("Retour des le"),
              //   type: "date",
              //   alwaysVisible: true,
              // },
              // {
              //   key: "dateMaxiRet",
              //   label: t("retourJusquau"),
              //   type: "date",
              //   alwaysVisible: true,
              // },

              {
                key: "etiqCdeCli",
                label: "Étiqueter les commandes",
                type: "select",
                options: [
                  { label: "Étiquetage habituel", value: "0" },
                  {
                    label: "Étiqueter toutes les commandes client",
                    value: "1",
                  },
                  {
                    label: "Ne pas étiqueter les commandes client",
                    value: "2",
                  },
                ],
                alwaysVisible: true,
              },
            ]}
          />
          <div className="flex flex-col mt-4 gap-1">
            <Info>Commentaire interne</Info>
            <Input
              multiline={true}
              value={selectedReception.comm}
              onChange={(e) =>
                changeSelectedReception({
                  ...selectedReception,
                  comm: e.target.value,
                })
              }
            />
          </div>
        </Frame>
      </div>
    </Page>
  );
};
