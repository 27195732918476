import _ from "lodash";
import { nanoid } from "nanoid";
import { useRecoilCallback, useSetRecoilState } from "recoil";
import { ProductsBasketType } from "../types";
import { ProductsBasketsAtom } from "./store";
import { Client } from "@features/customer/type";

// Ensure there is always a single empty basket
const removeEmptyBaskets = (baskets: ProductsBasketType[]) => {
  if (baskets.filter((basket) => basket.products.length === 0)?.length !== 1) {
    return [
      {
        id: nanoid(),
        created_at: Date.now(),
        products: [],
      },
      ...baskets.filter((basket) => basket.products.length > 0),
    ];
  }
  return baskets;
};

export const useSetBaskets = () => {
  const setProductsBaskets = useSetRecoilState(ProductsBasketsAtom);

  // Add basket
  const addBasket = useRecoilCallback(
    ({ snapshot }) =>
      (basket?: Partial<ProductsBasketType>) => {
        const productsBaskets = [
          ...snapshot.getLoadable(ProductsBasketsAtom).getValue(),
        ];
        const updatedProductsBaskets = [
          ...productsBaskets,
          Object.assign(
            { id: nanoid(), created_at: Date.now(), products: [] },
            basket
          ),
        ];
        setProductsBaskets(removeEmptyBaskets(updatedProductsBaskets));
      },
    [setProductsBaskets]
  );

  // Remove basket
  const removeBasket = useRecoilCallback(
    ({ snapshot }) =>
      (id: string) => {
        const productsBaskets = snapshot
          .getLoadable(ProductsBasketsAtom)
          .getValue();
        setProductsBaskets(
          removeEmptyBaskets(productsBaskets.filter((el) => el.id !== id))
        );
      },
    [setProductsBaskets]
  );

  // Update basket content
  const updateBasket = useRecoilCallback(
    ({ snapshot }) =>
      (
        id: string,
        operation: {
          replace?: ProductsBasketType["products"];
          add?: ProductsBasketType["products"];
          remove?: ProductsBasketType["products"];
          aside?: ProductsBasketType["products"];
          changeStatu?: ProductsBasketType["products"];
        }
      ) => {
        const productsBaskets = snapshot
          .getLoadable(ProductsBasketsAtom)
          .getValue();
        const updatedProductsBaskets = productsBaskets.map((e) => {
          if (e.id === id) {
            let products = _.cloneDeep(operation.replace ?? e.products);
            if (operation.add) {
              operation.add.forEach((product) => {
                const existingProductIndex = products.findIndex(
                  (el) => el.product.id === product.product.id
                );
                if (existingProductIndex !== -1) {
                  products.splice(existingProductIndex, 1, {
                    ...products[existingProductIndex],
                    count: products[existingProductIndex].count + product.count,
                  });
                } else {
                  products.push(product);
                }
              });
            }
            if (operation.remove) {
              operation.remove.forEach((product) => {
                const existingProductIndex = products.findIndex(
                  (el) => el.product.id === product.product.id
                );
                if (existingProductIndex !== -1) {
                  products.splice(existingProductIndex, 1, {
                    ...products[existingProductIndex],
                    count: products[existingProductIndex].count - product.count,
                  });
                }
              });
            }
            if (operation.aside) {
              operation.aside.forEach((product) => {
                const existingProductIndex = products.findIndex(
                  (el) => el.product.id === product.product.id
                );
                if (existingProductIndex !== -1) {
                  products.splice(existingProductIndex, 1, {
                    ...products[existingProductIndex],
                    count: products[existingProductIndex].count,
                    aside: !products[existingProductIndex].aside,
                  });
                }
              });
            }

            if (operation.changeStatu) {
              operation.changeStatu.forEach((product) => {
                const existingProductIndex = products.findIndex(
                  (el) => el.product.id === product.product.id
                );
                if (existingProductIndex !== -1) {
                  products.splice(existingProductIndex, 1, {
                    ...products[existingProductIndex],
                    count: products[existingProductIndex].count,
                    statu: product.statu,
                  });
                }
              });
            }
            products = products.filter((el) => el.count > 0);
            return { ...e, products };
          }

          return e;
        });
        setProductsBaskets(removeEmptyBaskets(updatedProductsBaskets));
      },
    [setProductsBaskets]
  );

  const changeBasketCustomer = useRecoilCallback(
    ({ snapshot }) =>
      (id: string, customer: Client) => {
        const productsBaskets = snapshot
          .getLoadable(ProductsBasketsAtom)
          .getValue();
        const updatedProductsBaskets = productsBaskets.map((e) => {
          if (e.id === id) {
            return { ...e, customer };
          } else {
            return e;
          }
        });
        setProductsBaskets(updatedProductsBaskets);
      }
  );

  return {
    addBasket,
    removeBasket,
    updateBasket,
    changeBasketCustomer,
  };
};
