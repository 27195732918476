import { Input, InputProps } from "@atoms/input/input-text";
import { Loader } from "@atoms/loader";
import { BaseSmall } from "@atoms/text";
import { ReceptionSearchResult } from "@features/reception/types";
import _ from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "@features/routes";
import { SvgIconRender } from "@atoms/icons";
import { useReception } from "@features/reception/state/use-reception";

// ... (existing imports)

// Pourra sûrement être factorisé avec le InputWithSuggestions
type InputSuggestionReceptionProps = {
  options: ReceptionSearchResult[];
  loading?: boolean;
  autoFocus?: "scan" | "keyboard" | boolean;
} & Omit<InputProps, "autoFocus">;

export const InputWithSuggestionsReception = (
  props: InputSuggestionReceptionProps
) => {
  const [focus, setFocus] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const {
    getHeaderReceipt,
    changeSelectedReception,
    refreshAllOngoingReceipt,
  } = useReception();
  const value = typeof props.value === "string" ? props.value : "";
  const ref = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();

  const onKeyDown = (e: any) => {
    switch (e.key) {
      case "ArrowUp":
        e.preventDefault();
        e.stopPropagation();
        setSelectedIndex((prevIndex) => Math.max(prevIndex - 1, -1));
        break;
      case "ArrowDown":
        e.preventDefault();
        e.stopPropagation();
        setSelectedIndex((prevIndex) =>
          Math.min(prevIndex + 1, props.options.length - 1)
        );
        break;
      case "Enter":
        if (selectedIndex >= 0 && selectedIndex < props.options.length) {
          props.onChange &&
            props.onChange({
              target: { value: props.options[selectedIndex].codeFourn },
            } as any);
          setFocus(false);
        }
        break;
      default:
        break;
    }
  };

  const event = useCallback((event: KeyboardEvent) => {
    if (
      event.key?.match(/^[A-Za-z0-9]$/i) &&
      !event.shiftKey &&
      !event.metaKey &&
      !event.ctrlKey &&
      !event.altKey &&
      !document.activeElement?.tagName?.match(/input|textarea|select/i)
    ) {
      ref.current?.focus();
    }
  }, []);

  useEffect(() => {
    if (props.autoFocus === true || props.autoFocus === "keyboard") {
      window.addEventListener("keydown", event);
    }
    return () => window.removeEventListener("keydown", event);
  }, [event, props.autoFocus]);

  useEffect(() => {
    setSelectedIndex(-1);
  }, [value]);

  return (
    <div className={"relative w-full "}>
      <Input
        inputRef={ref}
        autoFocus={props.autoFocus === "keyboard" || props.autoFocus === true}
        {..._.omit(props, "options", "loading", "autoFocus")}
        onFocus={(e) => {
          setFocus(true);
          props.onFocus && props.onFocus(e as any);
        }}
        onBlur={(e) => {
          setFocus(false);
          props.onBlur && props.onBlur(e as any);
        }}
        onKeyDown={(e) => onKeyDown(e)}
      />
      {props.loading && (
        <div className="absolute top-1/2 right-2 transform -translate-y-1/2 h-full flex items-center">
          <Loader />
        </div>
      )}
      {focus && !!props.options?.length && value.length > 0 && (
        <div className="absolute z-10 top-full left-0 w-full bg-white shadow-md max-h-lg overflow-auto dark:bg-slate-800">
          {_.uniqBy(props.options, "id").map((e: any, index: number) => (
            <div
              key={index}
              className={`py-1 px-2 cursor-pointer hover:bg-gray-100 dark:hover:bg-slate-500 ${
                selectedIndex === index ? "bg-gray-200" : ""
              }`}
              onMouseDown={async () => {
                if (e.orig !== "FOURN" && e.orig !== "RECEP") {
                  changeSelectedReception(
                    await getHeaderReceipt({
                      ...e,
                      //orig: e.numCde !== "" ? "CDE" : "COLIS",
                    })
                  );
                }
                if (e.orig === "FOURN") {
                  await refreshAllOngoingReceipt(undefined, {
                    trait: false,
                    codeFourn: e.codeFourn,
                  });
                  navigate(
                    ROUTES.SupplierPage.replace(/:codeFourn/, e.codeFourn)
                  );
                  return;
                }
                e.orig === "RECEP"
                  ? navigate(ROUTES.OngoingReception.replace(/:numBL/, e.numBL))
                  : navigate(ROUTES.ReceptionHeaderPage);
              }}
            >
              <div className="flex w-full gap-2 items-center">
                {e.orig === "FOURN" ? (
                  <SvgIconRender
                    src="/medias/truck.svg"
                    contentClassName="w-[20px] h-[20px]"
                  />
                ) : e.recepOuver ? (
                  <SvgIconRender
                    src="/medias/box-opened.svg"
                    contentClassName="w-[20px] h-[20px]"
                  />
                ) : (
                  <SvgIconRender
                    src="/medias/box-closed.svg"
                    contentClassName="w-[20px] h-[20px]"
                  />
                )}
                {e.orig === "FOURN" ? (
                  <BaseSmall>{e.codeFourn}</BaseSmall>
                ) : (
                  <BaseSmall>{getText(e)}</BaseSmall>
                )}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const getText = (recep: any) => {
  switch (recep.orig) {
    case "livr_fourn":
      return `BL - ${recep.numBL} (${recep.codeFourn})`;
    case "COLIS":
      return `COLIS - ${recep.idColis} ${
        recep.numBL !== "" ? `- (BL ${recep.numBL}) ` : ""
      }- (${recep.codeFourn})`;
    case "RECEP":
      return `Reception - ${recep.numRecep} (${recep.codeFourn})`;
    case "CDE":
      return `Commande - ${recep.numCde} (${recep.codeFourn})`;
    case "ATTR":
      return "Attendu reception";
    case "AE":
      return `BL - ${recep.numBL} (${recep.codeFourn})`;
    default:
      return "Code inconnu";
  }
};
