import { Button } from "@atoms/button/button";
import { ButtonConfirm } from "@atoms/button/confirm";
import { Shortcut } from "@features/utils/shortcuts";
import { MinusIcon, PlusIcon, TrashIcon } from "@heroicons/react/outline";
import { NumericFormat } from "react-number-format";
import { useContext, useEffect, useState } from "react";
import { ScanContext } from "@views/scan-provider";

export const InputCounter = (props: {
  min?: number;
  max?: number;
  confirmOnZero?: boolean;
  deleteIconOnZero?: boolean;
  value: number;
  onChange: (value: number) => void;
  size?: "sm" | "md" | "lg";
  shortcutAdd?: Shortcut[];
  shortcutRemove?: Shortcut[];
  disabled?: boolean;
}) => {
  const [value, setValue] = useState(`${props.value}`);
  const context = useContext(ScanContext);

  useEffect(() => {
    setValue(`${props.value}`);
  }, [props.value]);

  const onChange = (value: number) => {
    props.onChange(
      Math.max(
        props.min === undefined ? 0 : props.min,
        Math.min(
          props.max === undefined ? Number.MAX_SAFE_INTEGER : props.max,
          value
        )
      )
    );
  };

  const size = props.size || "sm";

  return (
    <div
      className="flex flex-row"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      {props.value > 1 || !props.deleteIconOnZero || !props.confirmOnZero ? (
        <Button
          disabled={
            props.disabled ||
            (props.min && `${value}` === `${props.min}`) ||
            (props.min && props.value < props.min) ||
            props.min === props.value ||
            false
          }
          size={size}
          theme={
            props.deleteIconOnZero && `${value}` === "1" ? "danger" : "primary"
          }
          className="shrink-0 z-9"
          icon={(p) =>
            (props.deleteIconOnZero && `${value}` === "1") ||
            (props.deleteIconOnZero &&
              props.min &&
              `${value}` === `${props.min}`) ? (
              <TrashIcon {...p} />
            ) : (
              <MinusIcon {...p} />
            )
          }
          onClick={(e) => {
            e.stopPropagation();
            onChange(props.value - 1);
          }}
          shortcut={props.shortcutRemove}
        />
      ) : (
        <ButtonConfirm
          size={size}
          disabled={props.disabled}
          theme={props.deleteIconOnZero ? "danger" : "primary"}
          className="shrink-0 z-10"
          confirmTitle="Suppression d'un article"
          confirmMessage="Voulez-vous supprimer cet article de votre panier ?"
          confirmButtonText="Oui"
          cancelButtonText="Non"
          icon={(p) =>
            props.deleteIconOnZero ? <TrashIcon {...p} /> : <MinusIcon {...p} />
          }
          onClick={() => onChange(props.value - 1)}
          shortcut={props.shortcutRemove}
        />
      )}
      <NumericFormat
        value={value}
        allowNegative={false}
        max={props.max || 999}
        onFocus={() => {
          context.setSaveFocused &&
            context.setSaveFocused(props.value.toString());
        }}
        onBlur={(e) => {
          if (context.saveFocused && context.scanData) {
            onChange(+context.saveFocused);
            setValue(context.saveFocused);
          } else {
            onChange(+e.target.value || 0);
          }
          context.setSaveFocused && context.setSaveFocused(null);
        }}
        onChange={(e) => {
          e.stopPropagation();
          onChange(+e.currentTarget.value);
        }}
        isAllowed={(values) => {
          const { floatValue } = values;
          let isValid =
            floatValue !== undefined &&
            floatValue <= (props.max ? props.max : 999);
          return isValid;
        }}
        className="min-w-[25%] !border-blue-500 !border !border-solid px-0 py-0 text-center -mx-px"
      />

      <Button
        size={size}
        disabled={props.disabled}
        className="shrink-0"
        icon={(p) => <PlusIcon {...p} />}
        onClick={(e) => {
          e.stopPropagation();
          onChange(props.value + 1);
        }}
        shortcut={props.shortcutAdd}
      />
    </div>
  );
};
