import { Button } from "@atoms/button/button";
import { Modal, ModalContent } from "@atoms/modal/modal";
import { useReception } from "@features/reception/state/use-reception";
import { ROUTES } from "@features/routes";
import { Table } from "@molecules/table";
import { Column } from "@molecules/table/table";
import { useNavigate } from "react-router-dom";
import { atom, useRecoilState } from "recoil";

type UnknownArticlesModalType = {
  open: boolean;
  unknownArticles: { ean13: string; designation: string }[];
};

export const UnknownArticlesModalAtom = atom<UnknownArticlesModalType>({
  key: "UnknownArticlesModal",
  default: { open: false, unknownArticles: [] },
});

export const UnknownArticlesModal = () => {
  const [unknownArticlesModal, setUnknownArticlesModal] = useRecoilState(
    UnknownArticlesModalAtom
  );
  const { selectedReception } = useReception();
  const navigate = useNavigate();

  const numCde: Column<{ ean13: string; designation: string }> = {
    title: "EAN 13",
    render: (el) => el.ean13,
  };
  const select: Column<{ ean13: string; designation: string }> = {
    render: (el) => el.designation,
  };

  const handleClose = () => {
    setUnknownArticlesModal({ open: false, unknownArticles: [] });
  };

  return (
    <Modal
      className="sm:max-w-[900px]"
      open={unknownArticlesModal.open}
      onClose={() => handleClose()}
    >
      <ModalContent title={"Produits inconnus"}>
        <div className="w-full">
          <Table
            emptyTabText={"Aucune commande associée"}
            columns={[numCde, select]}
            data={unknownArticlesModal.unknownArticles}
          />
        </div>
        <Button
          onClick={() => {
            handleClose();
            navigate(
              ROUTES.OngoingReception.replace(/:numBL/, selectedReception.numBL)
            );
          }}
        >
          Continuer
        </Button>
      </ModalContent>
    </Modal>
  );
};
