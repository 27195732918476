import { Frame } from "@atoms/layout/frame";
import { Modal, ModalContent } from "@atoms/modal/modal";
import { Subtitle } from "@atoms/text";
import { useReception } from "@features/reception/state/use-reception";
import { Form } from "@molecules/form";
import { SearchFormFieldType } from "@molecules/form/types";
import { atom, useRecoilState } from "recoil";

export const ReceptionInfoModalAtom = atom({
  key: "ReceptionInfoModalAtom",
  default: false,
});

export const receptionInfoModalFields: SearchFormFieldType[] = [
  {
    key: "nbRef",
    label: "Nombre de références",
    type: "number",
  },
  {
    key: "qteRecu",
    label: "Nombre d'articles",
    type: "number",
    alwaysVisible: true,
  },
  {
    key: "netHT",
    label: "Prix total net HT",
    type: "formatted",
    format: "price",
  },
  {
    key: "netTTC",
    label: "Prix total net TTC",
    type: "formatted",
    format: "price",
  },
  {
    key: "brutHT",
    label: "Prix total brut HT",
    type: "formatted",
    format: "price",
  },
  {
    key: "brutTTC",
    label: "Prix total brut TTC",
    type: "formatted",
    format: "price",
  },
];

export const ReceptionInfoModal = () => {
  const { selectedReception } = useReception();
  const [receptionInfoModal, setReceptionInfoModal] = useRecoilState(
    ReceptionInfoModalAtom
  );

  return (
    <Modal
      className="sm:max-w-[900px]"
      open={receptionInfoModal}
      onClose={() => setReceptionInfoModal(false)}
    >
      <ModalContent title={"Information sur réception"}>
        <div className="flex flex-col items-center gap-2">
          <Subtitle className="text-red-400">{`${selectedReception.raisSocial} - ${selectedReception.numBL}`}</Subtitle>
          <Frame className="w-full">
            <Form
              readonly={true}
              fields={receptionInfoModalFields}
              value={selectedReception}
              onChange={() => {}}
            ></Form>
          </Frame>
        </div>
      </ModalContent>
    </Modal>
  );
};
