const Env = {
  server: "", // Used in CI to call relative path for server
  version: "20240603-130904",
  //If domain is inferenceweb-integration.praxiel.fr then server is "https://api.infew.praxiel.fr"
  ...(document.location.hostname.includes("inferenceweb-integration.praxiel.fr")
    ? {
        server: "https://api.infew.praxiel.fr",
      }
    : {}),
  //If domain is inferenceweb.praxiel.fr then server is "https://api.inferencewe.praxiel.fr"
  ...(document.location.hostname.includes("inferenceweb.praxiel.fr")
    ? {
        server: "https://api.inferenceweb.praxiel.fr",
      }
    : {}),
  //If domain is inferenceweb.lagrandlibrairie.com then server is "https://api.infew.lagrandlibrairie.com"
  ...(document.location.hostname.includes("inferenceweb.lagrandlibrairie.com")
    ? {
        server: "https://api.infew.lagrandlibrairie.com",
      }
    : {}),
  //If domain is inferenceweb.majuscule-cambrai.com then server is "https://api.infew.majuscule-cambrai.com"
  ...(document.location.hostname.includes("inferenceweb.majuscule-cambrai.com")
    ? {
        server: "https://api.infew.majuscule-cambrai.com",
      }
    : {}),
  //If domain is infweb-4vents.praxiel.fr then server is "https://api.infew.4vents.praxiel.fr"
  ...(document.location.hostname.includes("infweb-4vents.praxiel.fr")
    ? {
        server: "https://api.infew.4vents.praxiel.fr",
      }
    : {}),
  //If domain is inferenceweb.librairie-mary.com then server is "https://api.infew.librairie-mary.com"
  ...(document.location.hostname.includes("inferenceweb.librairie-mary.com")
    ? {
        server: "https://api.infew.librairie-mary.com",
      }
    : {}),
  //If domain is inferenceweb-recette.praxiel.fr then server is "https://api.infew.recette.praxiel.fr"
  ...(document.location.hostname.includes("inferenceweb-recette.praxiel.fr")
    ? {
        server: "https://api.infew.recette.praxiel.fr",
      }
    : {}),
    isBeta:true,
    defaultExternalDB: true,
    maxInactiv: 180,
    lastRefresh: 0,
    refreshFreq: 30,
    debugAlert: false,
};

export default Env;

