import { BookRender } from "@atoms/book";
import { Button } from "@atoms/button/button";
import { InputCounter } from "@atoms/input/input-counter";
import { Frame } from "@atoms/layout/frame";
import { Page } from "@atoms/layout/page";
import { Base, Info, InfoSmall, InfoSmallBold, Title } from "@atoms/text";
import { useProduct } from "@features/products/state/use-product";
import { useControlledEffect } from "@features/utils/hooks/use-controlled-effect";
import { usePageNavigation } from "@features/utils/navigation";
import { CheckCircleIcon } from "@heroicons/react/outline";
import { Form } from "@molecules/form";
import { Table } from "@molecules/table";
import { Column } from "@molecules/table/table";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { StockTab } from "@components/product/stockTab";
import { PageLoader } from "@atoms/layout/page-loader";
import { NotAvailablePage } from "@views/client/not-available";
import { SearchFormFieldType } from "@molecules/form/types";
import toast from "react-hot-toast";
import { FaExclamationTriangle } from "react-icons/fa";
import { ButtonConfirm } from "@atoms/button/confirm";
import { useSuppliers } from "@features/supplier/state/use-suppliers";
import { ReturnType } from "@features/supplier/types";
import { useShopLocations } from "@features/general/auth/state/use-store-location";
import { flushGlobalEffects } from "@features/utils/hooks/use-global-effect";

//Peut être plus tard à factoriser avec la commande --> En attente d'info

export const SupplierRetourOneClickPage = () => {
  const { goToPreviousPage } = usePageNavigation();
  const { id, type } = useParams<{
    id: string;
    type: string;
  }>();
  const { product, loading } = useProduct(id || "", "RETURN");
  const {
    loading: orderLoading,
    getMotif: getMotifRefus,
    createRetour,
  } = useSuppliers();
  const { current } = useShopLocations();
  const [returnObject, setReturnObject] = useState<ReturnType | null>(null);
  const [motifRefus, setMotifRefus] = useState<
    { value: string; label: string }[] | null
  >(null);

  const handleCreerLigneRetourPreparation = async () => {
    if (returnObject) {
      if (await createRetour(returnObject, current?.codeLieu || "")) {
        toast.success("Retour fournisseur crée avec succès");
        flushGlobalEffects();
        goToPreviousPage();
      } else {
        toast.error("Erreur: impossible de créer le retour fournisseur");
      }
      return;
    }
  };

  useControlledEffect(() => {
    if (product !== null && product.supplementReturn) {
      const elIndex = product.supplementReturn?.findIndex(
        (el) => product.codeFournHabit === el.codeFourn
      );
      console.log(elIndex);
      console.log(product.supplementReturn[elIndex]);
      console.log(product.fourn);
      console.log(product.codeFourn);
      console.log({
        ...product,
        ...product.supplementReturn[elIndex],
        // fournReturn: {
        //   label: product.fourn,
        //   value: product.supplementReturn[elIndex].codeFourn,
        // },
        fournReturn: product.supplementReturn[elIndex].codeFourn,
        basket: "",
        motif: "",
        qteRet: product.supplementReturn[elIndex].qteRet,
        qteRetModif: product.supplementReturn[elIndex].qteRet,
      } as ReturnType);
      setReturnObject({
        ...product,
        ...product.supplementReturn[elIndex],
        fournReturn: product.supplementReturn[elIndex].codeFourn,
        basket: "",
        motif: "",
        qteRet: product.supplementReturn[elIndex].qteRet,
        qteRetModif: product.qteStock,
      } as ReturnType);
    }
  }, [product]);

  useControlledEffect(() => {
    const getMotif = async () => {
      setMotifRefus([
        { label: "<Aucun>", value: "" },
        ...(await getMotifRefus()),
      ]);
    };
    getMotif();
  }, []);

  const allColumns: Column<ReturnType>[] = [
    {
      title: "Article",
      orderable: true,
      render: (item, { responsive }) =>
        responsive ? (
          <div
            className="flex flex-col gap-1 mt-2 pt-2 overflow-hidden text-center"
            onClick={() => {}}
          >
            <InfoSmall
              className="overflow-hidden text-ellipsis"
              data-tooltip={item.designation}
            >
              {item.designation}
            </InfoSmall>
            <InfoSmallBold>{item.id}</InfoSmallBold>
          </div>
        ) : (
          <div
            className="flex flex-row items-center space-x-4 cursor-pointer"
            onClick={() => {}}
          >
            <div className="w-10 print:hidden">
              <BookRender src={item.imageURL} productType={item.modeGest} />
            </div>

            <div className="flex flex-col">
              <Base
                className="block overflow-hidden whitespace-nowrap text-ellipsis print:whitespace-normal max-w-xs"
                data-tooltip={item.designation}
              >
                {item.designation}
              </Base>
              <Info>{item.id}</Info>
            </div>
          </div>
        ),
    },
    {
      title: `Prix ${current?.devisSymb || "€"} PUT HT`,
      orderable: true,
      render: (item) => (
        <Base className="w-24 text-right">
          {item.prixVente.toFixed(current?.devisNbDecim)}{" "}
          {current?.devisSymb || "€"}
        </Base>
      ),
    },
    {
      title: "Quant.",
      orderable: true,
      render: (item) => (
        <div className="flex flex-col justify-center orderItems-center gap-1">
          <div style={{ maxWidth: 128, minWidth: 96 }}>
            <InputCounter
              size={"md"}
              value={item.qteRetModif}
              min={1}
              max={999}
              onChange={(value) => {
                setReturnObject((prev) => {
                  if (prev) return { ...prev, qteRetModif: value };
                  return null;
                });
              }}
            />
          </div>
        </div>
      ),
    },
  ];

  if (loading || !motifRefus) {
    return <PageLoader />;
  }

  if (!product) {
    return <NotAvailablePage />;
  }

  return (
    <Page>
      <Title>Préparation de Retour fournisseur: {product.designation}</Title>
      <div className="grow flex flex-col gap-2 my-2">
        <Table
          columns={allColumns}
          data={returnObject !== null ? [returnObject] : []}
        />
        <Frame>
          {returnObject !== null && (
            <Form
              value={returnObject}
              onChange={(newValue) => {
                let temp = { ...newValue };

                if (newValue.fournReturn !== returnObject.fournReturn) {
                  const fournInfos = product.supplementReturn!.find(
                    (el) => newValue.fournReturn === el.codeFourn
                  );
                  console.log(fournInfos);
                  temp = { ...newValue, ...fournInfos };
                }

                for (const cle of Object.keys(newValue)) {
                  if (Array.isArray(newValue[cle])) {
                    temp[cle] = newValue[cle][0];
                  }
                  if (temp[cle] === undefined) {
                    delete temp[cle];
                  }
                }
                setReturnObject(temp as ReturnType);
              }}
              fields={[
                {
                  key: "fournReturn",
                  label: "Fournisseur *",
                  type: "select",
                  placeholder: "Fournisseur...",
                  options: product.listFourn.map((el) => {
                    return { label: el.raisSocial, value: el.codeFourn };
                  }),
                } as SearchFormFieldType,
                {
                  key: "basket",
                  label: "Panier de retour",
                  type: "text",
                },
                {
                  key: "demandeAut",
                  label: "Demande d'autorisation",
                  type: "select_boolean",
                },
                {
                  key: "motif",
                  label: "Motif",
                  placeholder: "Motif...",
                  type: "select",
                  options: motifRefus || [],
                },
              ]}
            />
          )}
        </Frame>
        {returnObject && returnObject?.qteStock < returnObject?.qteRetModif && (
          <div className="flex w-full justify-center items-center text-red-400 gap-2">
            <FaExclamationTriangle />
            <Base>
              Attention, la quantité retournée est supérieure à la quantité en
              stock
            </Base>
          </div>
        )}
        <div className="w-full flex justify-evenly">
          <Button
            onClick={(e) => {
              flushGlobalEffects();
              goToPreviousPage();
            }}
            theme="danger"
          >
            Annuler
          </Button>
          {returnObject &&
          returnObject?.qteStock < returnObject?.qteRetModif ? (
            <ButtonConfirm
              disabled={
                returnObject && !returnObject.fournReturn ? true : false
              }
              confirmButtonDisabled={orderLoading}
              confirmButtonTheme="danger"
              onClick={async () => {
                if (returnObject && returnObject.fournReturn) {
                  await handleCreerLigneRetourPreparation();
                } else {
                  toast.error("Veuillez renseigner le fournisseur.");
                }
              }}
              confirmMessage="La quantité retournée est supérieure à la quantité en
              stock. Êtes-vous sûr de vouloir confirmer ?"
            >
              Valider
            </ButtonConfirm>
          ) : (
            <Button
              disabled={
                orderLoading ||
                (returnObject && !returnObject.fournReturn ? true : false)
              }
              icon={(p) => <CheckCircleIcon {...p} />}
              onClick={() => {
                if (returnObject && returnObject.fournReturn) {
                  handleCreerLigneRetourPreparation();
                } else {
                  toast.error("Veuillez renseigner le fournisseur.");
                }
              }}
            >
              Valider
            </Button>
          )}
        </div>
      </div>

      {product !== null && returnObject && (
        <>
          <div className="flex flex-col sm:flex-row gap-4">
            <Frame className="flex-auto">
              <Form
                value={returnObject}
                onChange={() => {}}
                readonly={true}
                fields={[
                  {
                    label: "Editeur / Fabricant",
                    key: type === "L" ? "editeur" : "codeFab",
                    type: "text",
                    alwaysVisible: true,
                  },
                  {
                    label: "Zone",
                    key: "numZone",
                    type: "text",
                    alwaysVisible: true,
                  },
                  {
                    label: "Rayon",
                    key: "libRayon",
                    type: "text",
                    alwaysVisible: true,
                    render: (v, vs) => {
                      return <Base>{`${v} (${vs.codeRayon})`}</Base>;
                    },
                  },
                  {
                    label: "Première entrée en stock",
                    key: "datePremEntre",
                    type: "date",
                    alwaysVisible: true,
                  },

                  {
                    label: "Dernière réception",
                    key: "dateDernRecep",
                    type: "date",
                    alwaysVisible: true,
                  },
                  {
                    label: "Dernier retour",
                    key: "dateDernRet",
                    type: "date",
                    alwaysVisible: true,
                  },
                  {
                    label: "Début droit de retour",
                    key: "dateDebDroitRet",
                    type: "date",
                    alwaysVisible: true,
                  },
                  {
                    label: "Fin droit de retour",
                    key: "dateFinDroitRet",
                    type: "date",
                    alwaysVisible: true,
                  },
                  {
                    label: "Qte actuellement en préparation de retour",
                    key: "qteRet",
                    type: "number",
                    alwaysVisible: true,
                  },
                  {
                    label: "Quantité droit de retour",
                    key: "qteDroitRet",
                    type: "number",
                    alwaysVisible: true,
                  },
                  {
                    label: "Quantité vendue",
                    key: "qteVente",
                    type: "number",
                    alwaysVisible: true,
                  },
                ]}
              />
            </Frame>
            <div className="flex-auto">
              <StockTab product={product} />
            </div>
          </div>
        </>
      )}
    </Page>
  );
};
