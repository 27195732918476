import { Page } from "@atoms/layout/page";
import { Base, Section, Title } from "@atoms/text";
import { useCallback, useState } from "react";
import { useReception } from "@features/reception/state/use-reception";
import { ReceptionCard, getLibel } from "@components/reception/reception-cards";
import { useControlledEffect } from "@features/utils/hooks/use-controlled-effect";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "@features/routes";
import {
  ReceptionSearchResult,
  ReceptionUnit,
} from "@features/reception/types";
import { InputWithSuggestionsReception } from "@components/reception/input-suggestion-reception";
import { Column } from "@molecules/table/table";
import { TableGridSwitch } from "@molecules/table/table-grid-switch";
import { useSetRecoilState } from "recoil";
import { CommonConfirmModalAtom } from "@molecules/confirm-modal";
import { FaExclamationTriangle } from "react-icons/fa";
import { useScan } from "@atoms/input-scan/use-scan";
import { usePersistedState } from "@features/utils/hooks/use-local-storage";
import { Button } from "@atoms/button/button";
import { ReceptionInfoModalAtom } from "./receptions-modals/reception-info-modal";
import { InformationCircleIcon } from "@heroicons/react/outline";
import { toDateISO } from "@features/utils/format/dates";
import { BookRender } from "@atoms/book";

export const ReceptionPage = () => {
  const [value, setValue] = useState("");
  const setConfirmModal = useSetRecoilState(CommonConfirmModalAtom);
  const [searchResult, setSearchResult] = useState<ReceptionSearchResult[]>([]);
  const {
    mainSearch,
    refreshAllOngoingReceipt,
    changeSelectedReception,
    allOngoingReceipt,
  } = useReception();

  const [gridMode, setGridMode] = usePersistedState(
    "user",
    "reception-all-grid-mode",
    {
      activated: false,
    }
  );

  const [currentOrder, setCurrentOrder] = usePersistedState<any>(
    "user",
    "reception-all-order",
    {
      orderBy: 0,
      orderDir: "DESC",
    }
  );

  const [numPage, setNumPage] = usePersistedState(
    "user",
    "reception-all-num-page",
    1
  );

  const navigate = useNavigate();
  const { scanOn } = useScan();
  const setReceptionInfoModal = useSetRecoilState(ReceptionInfoModalAtom);

  // useControlledEffect(() => {
  //   refreshAllOngoingReceipt(undefined, { trait: false });
  // }, []);

  const handleSearch = useCallback(
    async (
      { page, perPage, order } = { page: 1, perPage: 25, order: "titre" }
    ) => {
      setSearchResult(
        await mainSearch({
          query: value,
          orderBy: order,
          orderDir: "ASC",
          pageNumber: page,
          pageSize: perPage,
        })
      );
    },
    [mainSearch, value]
  );

  const fournisseur: Column<ReceptionUnit> = {
    title: "Fournisseur",
    render: (el) => (
      <div className="flex gap-2">
        <div className="flex flex-col justify-center items-end w-6 h-6">
          <BookRender src="" productType={el.modeGest} />
        </div>
        <Base>{el.codeFourn}</Base>
      </div>
    ),
  };

  const numeroBL: Column<ReceptionUnit> = {
    title: "Num. BL",
    render: (el) => el.numBL,
  };

  const numero: Column<ReceptionUnit> = {
    title: "Num. reception",
    render: (el) => el.numRecep,
  };

  const avex: Column<ReceptionUnit> = {
    title: "Type reception",
    render: (el) => getLibel(el),
  };
  const nbColis: Column<ReceptionUnit> = {
    title: "Nombre de colis",
    render: (el) => (
      <Base className="lg:w-28 md:text-center lg:text-right">
        {el.nbrColis}
      </Base>
    ),
  };

  const dateRecep: Column<ReceptionUnit> = {
    orderable: true,
    title: "Date",
    render: (el) => toDateISO(el.dateRecep),
  };

  const infosModal: Column<ReceptionUnit> = {
    title: "Infos",
    render: (el) => (
      <Button
        onClick={(event) => {
          changeSelectedReception(el);
          setReceptionInfoModal(true);
          event.stopPropagation();
        }}
        icon={({ className }) => (
          <InformationCircleIcon className={className} />
        )}
      />
    ),
  };

  useControlledEffect(() => {
    handleSearch();
  }, [value]);

  return (
    <Page>
      <Title>Réception</Title>
      <div className="w-full flex justify-center">
        <div className="w-3/4 sm:w-1/3 my-2">
          <InputWithSuggestionsReception
            options={searchResult}
            onChange={(e) => setValue(e.target.value)}
            inputMode={scanOn ? "none" : undefined}
            inputClassName="to-focus"
            autoFocus={true}
            value={value}
            placeholder="Chercher/Biper, une réception, une commande, un fournisseur..."
          />
        </div>
      </div>
      <div className="grow overflow-hidden">
        <Section>Liste des receptions en cours</Section>
        <TableGridSwitch
          data={allOngoingReceipt.items}
          gridMode={gridMode.activated}
          onChangeMode={(v) => setGridMode({ activated: v })}
          showPagination={"full"}
          cellClassName={() => "rounded-lg"}
          initialPagination={{
            perPage: 15,
            page: numPage,
            order: currentOrder.orderDir,
            orderBy: currentOrder.orderBy,
          }}
          onClick={(receipt) => {
            if (receipt.recepOuver) {
              setConfirmModal({
                theme: "danger",
                icon: () => (
                  <FaExclamationTriangle className="text-red-600 h-6 w-6" />
                ),
                open: true,
                title: "Réception en cours",
                message:
                  "Cette réception est déjà en cours de traitement par: INFER. Souhaitez-vous continuer ?",
                onAccept: () => {
                  changeSelectedReception(receipt);
                  navigate(
                    ROUTES.OngoingReception.replace(/:numBL/, receipt.numBL)
                  );
                },
              });
            } else {
              changeSelectedReception(receipt);
              navigate(
                ROUTES.OngoingReception.replace(/:numBL/, receipt.numBL)
              );
            }
          }}
          onFetchExportData={async () => {
            return allOngoingReceipt.items;
          }}
          exportDefaultHeader={[
            "codeFourn",
            "indicFactFourn",
            "numRecep",
            "numAttenRecep",
            "nbrColis",
          ]}
          tableColumns={[
            fournisseur,
            numeroBL,
            numero,
            avex,
            nbColis,
            dateRecep,
            infosModal,
          ]}
          renderGrid={(receipt) => (
            <ReceptionCard item={receipt as ReceptionUnit} />
          )}
          total={allOngoingReceipt.pagination.totalRows}
          orderables={["Date", "Fournisseur"]}
          onRequestData={async ({ page, perPage, order, orderBy }) => {
            setCurrentOrder({
              orderBy: orderBy,
              orderDir: order || "DESC",
            });
            refreshAllOngoingReceipt(
              {
                pageNumber: page,
                pageSize: perPage,
                orderBy: orderBy === 0 ? "dateRecep" : "codeFourn",
                orderDir: order,
              },
              { trait: false }
            );
            setNumPage(page);
          }}
          gridClassName={
            "grid gap-4 grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-6"
          }
        />
      </div>
    </Page>
  );
};
