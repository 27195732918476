import environment from "@config/environment";
import { AuthJWT } from "../general/auth/jwt";
import toast from "react-hot-toast";
import { updateLastRequestTime } from "./utils-global-state";

export const fetchServer = async (
  url: string,
  options: RequestInit & { reloadOn401?: boolean; reloadOn403?: boolean } = {
    reloadOn401: true,
    reloadOn403: true,
  }
): Promise<Response> => {
  updateLastRequestTime();
  refreshLastRequestTime();

  // Automatically include the token and set the content type
  options.headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    "X-CodeLieu": AuthJWT.codeLieu || "",
    ...(AuthJWT.token ? { Authorization: `Bearer ${AuthJWT.token}` } : {}),
    ...options.headers,
  };
  //Automatically include the codeLieu
  // if (options.body && AuthJWT.codeLieu && typeof options.body === "string") {
  //   try {
  //     const obj = JSON.parse(options.body);
  //     obj.codeLieu = AuthJWT.codeLieu;
  //     options.body = JSON.stringify(obj);
  //   } catch (e) {}
  // }
  const data = await fetch(environment.server + url, options);

  if (data.status === 500) {
    const res = await data.json()
    toast.error("Une erreur interne est survenue: " + res.detail);
  }
  if (data.status === 401 && options.reloadOn401 !== false) {
    document.location.reload();
  }
  if (data.status === 403 && options.reloadOn403 !== false) {
    const res = await data.json();
    toast.error(res.detail);
    setTimeout(() => {
      document.location.reload();
    }, 3000);
  }

  return data;
};

const refreshLastRequestTime = (): void => {
  const event = new CustomEvent<number>("lastRequestTimeUpdated", {
    detail: Date.now(),
  });
  window.dispatchEvent(event);
};
