import { BookRender } from "@atoms/book";
import { Button } from "@atoms/button/button";
import { InputCounter } from "@atoms/input/input-counter";
import { Frame } from "@atoms/layout/frame";
import { Page } from "@atoms/layout/page";
import { Base, Info, Title } from "@atoms/text";
import { useProduct } from "@features/products/state/use-product";
import { useControlledEffect } from "@features/utils/hooks/use-controlled-effect";
import { usePageNavigation } from "@features/utils/navigation";
import { CheckCircleIcon } from "@heroicons/react/outline";
import { Form } from "@molecules/form";
import { Table } from "@molecules/table";
import { Column } from "@molecules/table/table";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { StockTab } from "@components/product/stockTab";
import { PageLoader } from "@atoms/layout/page-loader";
import { NotAvailablePage } from "@views/client/not-available";
import { SearchFormFieldType } from "@molecules/form/types";
import toast from "react-hot-toast";
import { useSuppliers } from "@features/supplier/state/use-suppliers";
import { CommandType } from "@features/supplier/types";
import { useShopLocations } from "@features/general/auth/state/use-store-location";
import { flushGlobalEffects } from "@features/utils/hooks/use-global-effect";

//Peut être plus tard à factoriser avec la commande --> En attente d'info

export const SupplierCommandOneClickPage = () => {
  const { goToPreviousPage } = usePageNavigation();
  const { id, type } = useParams<{
    id: string;
    type: string;
  }>();

  //const { product, loading } = useProduct(id || "");
  const { product, loading } = useProduct(id || "", "ORDER");
  const {
    loading: orderLoading,
    getModesExpedition,
    createOrder,
  } = useSuppliers();
  const { current } = useShopLocations();
  const [orderObject, setorderObject] = useState<CommandType | null>(null);
  const [modesExpedition, setModesExpedition] = useState<
    { value: string; label: string }[] | null
  >(null);

  const handleCreerCommandePreparation = async () => {
    if (orderObject && current?.codeLieu) {
      if (await createOrder(orderObject, current?.codeLieu)) {
        toast.success("Commande fournisseur crée avec succès.");
        flushGlobalEffects();
        goToPreviousPage();
      } else {
        toast.error("Erreur: Impossible de créer la commande fournisseur ");
      }
    }
    return;
  };

  useControlledEffect(() => {
    if (product !== null) {
      setorderObject({
        ...product,
        fournReturn: { label: product.fourn, value: product.codeFourn },
        basket: "",
        autorisation: false,
        motif: "",
        qteOrdered: 1,
        codeModeExpe: "9",
      } as CommandType);
    }
  }, [product]);

  useControlledEffect(() => {
    const getMotif = async () => {
      setModesExpedition([
        { label: "<Aucun>", value: "" },
        ...(await getModesExpedition()),
      ]);
    };
    getMotif();
  }, []);

  const allColumns: Column<CommandType>[] = [
    {
      title: "Article",
      orderable: true,
      render: (item, { responsive }) =>
        responsive ? (
          <div className="text-right" onClick={() => {}}>
            <Base data-tooltip={item.designation}>{item.designation}</Base>
            <br />
            <Info>{item.id}</Info>
          </div>
        ) : (
          <div
            className="flex flex-row items-center space-x-4 cursor-pointer"
            onClick={() => {}}
          >
            <div className="w-10 print:hidden">
              <BookRender src={item.imageURL} productType={item.modeGest} />
            </div>

            <div className="flex flex-col">
              <Base
                className="block overflow-hidden whitespace-nowrap text-ellipsis print:whitespace-normal max-w-xs"
                data-tooltip={item.designation}
              >
                {item.designation}
              </Base>
              <Info>{item.id}</Info>
            </div>
          </div>
        ),
    },
    {
      title: `Prix ${current?.devisSymb || "€"} PUT HT`,
      orderable: true,
      render: (item) => (
        <Base className="w-24 text-right">
          {item.prixVente.toFixed(current?.devisNbDecim || 2)}{" "}
          {current?.devisSymb || "€"}
        </Base>
      ),
    },
    {
      title: "Quant.",
      orderable: true,
      render: (item) => (
        <div className="flex flex-col justify-center orderItems-center gap-1">
          <div style={{ maxWidth: 128, minWidth: 96 }}>
            <InputCounter
              size={"md"}
              value={item.qteOrdered}
              min={1}
              max={999}
              onChange={(value) => {
                setorderObject((prev) => {
                  if (prev) return { ...prev, qteOrdered: value };
                  return null;
                });
              }}
            />
          </div>
        </div>
      ),
    },
  ];

  if (loading || !modesExpedition) {
    return <PageLoader />;
  }

  if (!product) {
    return <NotAvailablePage />;
  }

  return (
    <Page>
      <Title>Préparation de commande fournisseur: {product.designation}</Title>
      <div className="grow flex flex-col gap-8 my-12">
        <Table
          columns={allColumns}
          data={orderObject !== null ? [orderObject] : []}
        />
        <Frame>
          {orderObject !== null && (
            <Form
              value={orderObject}
              onChange={(newValue) => {
                let temp = { ...newValue };

                for (const cle of Object.keys(newValue)) {
                  if (Array.isArray(newValue[cle])) {
                    temp[cle] = newValue[cle][0];
                  }
                  if (temp[cle] === undefined) {
                    delete temp[cle];
                  }
                }
                setorderObject(temp as CommandType);
              }}
              fields={[
                {
                  key: "codeModeExpe",
                  label: "Mode d'expedition",
                  placeholder: "expe",
                  type: "select",
                  options: modesExpedition || [],
                },
                {
                  key: "fournReturn",
                  label: "Fournisseur *",
                  type: "select",
                  placeholder: "Fournisseur...",
                  options: product.listFourn.map((el) => {
                    return { label: el.raisSocial, value: el.codeFourn };
                  }),
                } as SearchFormFieldType,
                {
                  key: "basket",
                  label: "Panier",
                  type: "text",
                },
              ]}
            />
          )}
        </Frame>

        <div className="w-full flex justify-evenly">
          <Button
            onClick={(e) => {
              flushGlobalEffects();
              goToPreviousPage();
            }}
            theme="danger"
          >
            Annuler
          </Button>
          <Button
            disabled={
              orderLoading ||
              (orderObject && !orderObject.fournReturn ? true : false)
            }
            icon={(p) => <CheckCircleIcon {...p} />}
            onClick={() => {
              if (orderObject && orderObject.fournReturn) {
                handleCreerCommandePreparation();
              } else {
                toast.error("Veuillez renseigner le fournisseur.");
              }
            }}
          >
            Valider
          </Button>
        </div>
      </div>

      {product !== null && (
        <>
          <div className="flex flex-col sm:flex-row gap-4">
            <Frame className="flex-auto">
              <Form
                value={product}
                onChange={() => {}}
                readonly={true}
                fields={[
                  {
                    label: "Editeur / Fabricant",
                    key: type === "L" ? "editeur" : "codeFab",
                    type: "text",
                    alwaysVisible: true,
                  },
                  {
                    label: "Zone",
                    key: "numZone", //À brancher
                    type: "text",
                    alwaysVisible: true,
                  },
                  {
                    label: "Rayon",
                    key: "libRayon",
                    type: "text",
                    alwaysVisible: true,
                    render: (v, vs) => {
                      return <Base>{`${v} (${vs.codeRayon})`}</Base>;
                    },
                  },
                  {
                    label: "Première entrée en stock",
                    key: "datePremEntre",
                    type: "date",
                    alwaysVisible: true,
                  },
                  {
                    label: "Date dernière commande",
                    key: "dateDernCde",
                    type: "date",
                    alwaysVisible: true,
                  },
                  {
                    label: "Dernière réception",
                    key: "dateDernRecep",
                    type: "date",
                    alwaysVisible: true,
                  },

                  {
                    label: "Date dernier retour",
                    key: "dateDernRet",
                    type: "date",
                    alwaysVisible: true,
                  },
                  {
                    label: "Disponibilité fournisseur",
                    key: "dispoFourn", //À brancher
                    alwaysVisible: true,
                  },
                  {
                    label: "Quantité vendue",
                    key: "qteVente", //À brancher
                    type: "number",
                    alwaysVisible: true,
                  },
                  {
                    label: "Quantité en préparation cde fourn",
                    key: "qtePrepCdePlusTrans",
                    type: "number",
                    alwaysVisible: true,
                  },
                  {
                    label: "Couverture prévisionnelle (J)",
                    key: "couvPrevis  ", //À brancher
                    type: "text",
                    alwaysVisible: true,
                  },
                ]}
              />
            </Frame>
            <div className="flex-auto">
              <StockTab product={product} />
            </div>
          </div>
        </>
      )}
    </Page>
  );
};
