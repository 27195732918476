import { useRecoilState, useSetRecoilState } from "recoil";
import { ReceptionApiClient } from "../api-client/api-client";
import {
  MissingArticle,
  ReceptionLine,
  ReceptionUnit,
  RelatedOrder,
  SearchReceptionRequestType,
} from "../types";
import {
  AllOngoingReceptions,
  ReceiptLines,
  SelectedReception,
  TabKey,
} from "./store";
import toast from "react-hot-toast";
import { LoadingState } from "@features/utils/store/loading-state-atom";
import { CommonPagination, CommonPaginationRequest } from "@features/types";
import { useProducts } from "@features/products/state/use-products";
import { toDateISO } from "@features/utils/format/dates";
import { AffectationCmdClientModalAtom } from "@views/client/reception/receptions-modals/affection-commande-client-modal";

export const useReception = () => {
  const [selectedReception, setSelectedReception] =
    useRecoilState(SelectedReception);

  const [allOngoingReceipt, setAllOngoingReceipt] =
    useRecoilState(AllOngoingReceptions);

  const [receiptLines, setReceiptLines] = useRecoilState(ReceiptLines);
  const { getProductScan } = useProducts();

  const [loading, setLoading] = useRecoilState(
    LoadingState(["useReception", true])
  );

  const setTabKey = useSetRecoilState(TabKey);

  const setAffectationModal = useSetRecoilState(AffectationCmdClientModalAtom);

  const getReceipt = async () => {
    const result = await ReceptionApiClient.getReceipt();
    return result;
  };

  const getLines = async (
    receipt: ReceptionUnit,
    pagination?: CommonPaginationRequest,
    loaderOn?: boolean
  ) => {
    if (loaderOn) setLoading(true);
    const result = await ReceptionApiClient.getLines(receipt, pagination);
    setLoading(false);
    return result;
  };

  const refresh = async (pagination?: {
    orderBy?: string;
    orderDir: "ASC" | "DESC" | undefined;
    pageNumber: number;
    pageSize: 15;
  }) => {
    setLoading(true);
    try {
      const result = await getLines(selectedReception, pagination);
      setReceiptLines({
        pagination: {
          pageNumber: result.pageNumber,
          pageSize: result.pageSize,
          pageCount: result.pageCount,
          totalRows: result.totalRows,
          rowsReturned: result.rowsReturned,
        },
        items: result.items,
      });
      setLoading(false);
    } catch (err) {
      toast.error("Réception non trouvée");
      setLoading(false);
    }
    setLoading(false);
  };

  const mainSearch = async (search: SearchReceptionRequestType) => {
    if (search.query.trim()) {
      const result = await ReceptionApiClient.mainSearch(search);
      return result;
    }
    return [];
  };

  const createHeader = async (recept: ReceptionUnit) => {
    const result = await ReceptionApiClient.createHeader(recept);
    return result;
  };

  const refreshAllOngoingReceipt = async (
    pagination?: CommonPaginationRequest,
    filters?: { utilCreat?: string; codeFourn?: string; trait?: boolean }
  ) => {
    setAllOngoingReceipt(
      await ReceptionApiClient.allReceipt(pagination, filters)
    );
  };

  const changeSelectedReception = (reception: ReceptionUnit) => {
    setSelectedReception(reception);
  };

  const printLabels = async (
    receptionLine?: ReceptionLine,
    nbrEtiq?: number
  ) => {
    setLoading(true);
    const res = await ReceptionApiClient.printLabels(
      selectedReception,
      receptionLine,
      nbrEtiq
    );
    if (!res) {
      toast.error("Impression des étiquettes impossible");
    }
    setLoading(false);
    return res;
  };

  const changeReceiptLines = (lines: {
    pagination: CommonPagination;
    items: ReceptionLine[];
  }) => {
    setReceiptLines(lines);
  };

  const getHeaderReceipt = async (infos: {
    codeLieu: string;
    orig: "AE" | "COLIS" | "CDE";
    codeFourn: string;
    numBL: string;
    numColis: string;
    numCde: string;
  }) => {
    const result = await ReceptionApiClient.getHeaderReceipt(infos);
    setSelectedReception(result);
    return result;
  };

  const receiptScannedProduct = async (ean: string, createRecep?: boolean) => {
    const objectExist = await getProductScan(ean);
    if (objectExist) {
      if (objectExist.modeGest !== selectedReception.modeGest) {
        toast.error(
          "Ce produit ne peut être ajouté à cette réception car leurs modes de gestion diffèrent."
        );
        return;
      }
      if (createRecep) {
        await createHeader(selectedReception);
      }
      const result = await ReceptionApiClient.getScannedProduct({
        receipt: {
          codeFourn: selectedReception.codeFourn,
          numBL: selectedReception.numBL,
          seq: selectedReception.seq,
          codeLieu: selectedReception.codeLieu,
          dateRecep: toDateISO(selectedReception.dateRecep),
        },
        productId: ean,
      });
      return result;
    }
  };

  const updateLine = async (
    product: ReceptionLine,
    orders?: RelatedOrder[],
    loaderOn?: boolean
  ) => {
    if (loaderOn) setLoading(true);
    try {
      const result = await ReceptionApiClient.updateLine(
        selectedReception,
        product,
        orders
      );
      setLoading(false);
      return result;
    } catch {
      setLoading(false);
    }
  };

  const rappatrierLines = async () => {
    const result = await ReceptionApiClient.rappatrierLines(selectedReception);
    return result;
  };

  const closeReceipt = async () => {
    const result = await ReceptionApiClient.closeReceipt(selectedReception);
    return result;
  };

  const getReceiptDocument = async (documentType: "ecart" | "recep") => {
    setLoading(true);
    try {
      const result = await ReceptionApiClient.getReceiptDocument(
        selectedReception,
        documentType
      );
      setLoading(false);
      return result;
    } catch {
      setLoading(false);
    }
  };

  const getRelatedOrders = async (
    receipt: ReceptionUnit,
    productId: string,
    qteRecu: number
  ) => {
    setLoading(true);
    const result = await ReceptionApiClient.getRelatedOrders(
      receipt,
      productId,
      qteRecu
    );
    setLoading(false);
    return result;
  };

  const handleCommit = async (
    selectedItem: ReceptionLine,
    refresh?: boolean
  ) => {
    if (selectedItem)
      if (selectedItem.qteCdeCli > 0) {
        const orders = await getRelatedOrders(
          selectedReception,
          selectedItem.eaN13,
          selectedItem.qteRecu
        );
        setAffectationModal({
          open: true,
          receipt: selectedReception,
          item: selectedItem,
          orders: orders,
        });
      } else {
        await updateLine(
          selectedItem,
          [
            {
              codeCanalVente: "",
              numCdeCli: "",
              dateCde: null,
              nomClient: "",
              telCli: "",
              mailCli: "",
              qteCde: 0,
              qteRest: 0,
              qteProposee: selectedItem.qteRecu,
              nbrEtiq: 0,
            },
          ],
          refresh
        );
        if (refresh)
          setTabKey((previous) => {
            return { count: previous.count + 1, refresh: true };
          });
        // const updatedLines = await getLines(selectedReception, {
        //   orderBy: "eaN13",
        //   orderDir: "DESC",
        //   pageNumber: 0,
        //   pageSize: 15,
        // });
        // //setSelectedItem(null);
        // setReceiptLines({
        //   pagination: {
        //     pageNumber: updatedLines.pageNumber,
        //     pageSize: updatedLines.pageSize,
        //     pageCount: updatedLines.pageCount,
        //     totalRows: updatedLines.totalRows,
        //     rowsReturned: updatedLines.rowsReturned,
        //   },
        //   items: updatedLines.items,
        // });
        //setSelectedItem(null);
        //setLock(false);
      }
  };

  const updateAllPrices = async () => {
    const res = await ReceptionApiClient.updateAllPrices(selectedReception);
    if (res) toast.success("Prix mis à jour avec succès");
  };

  const getMissingArticles = async () => {
    return await ReceptionApiClient.getMissingArticles(selectedReception);
  };

  const updateMissingArticles = async (missingArticles: MissingArticle[]) => {
    return await ReceptionApiClient.updateMissingArticles(
      selectedReception,
      missingArticles
    );
  };

  return {
    getReceipt,
    mainSearch,
    allOngoingReceipt,
    refreshAllOngoingReceipt,
    selectedReception,
    changeSelectedReception,
    changeReceiptLines,
    setReceiptLines,
    getLines,
    loading,
    refresh,
    receiptLines,
    getHeaderReceipt,
    receiptScannedProduct,
    getRelatedOrders,
    createHeader,
    updateLine,
    rappatrierLines,
    closeReceipt,
    printLabels,
    getReceiptDocument,
    handleCommit,
    getMissingArticles,
    updateAllPrices,
    updateMissingArticles,
  };
};
