import { Button } from "@atoms/button/button";
import { Form, SearchForm } from "@molecules/form";
import { Table } from "@molecules/table";
import { Column } from "@molecules/table/table";
import { useCustomer } from "@features/customer/state/use-customer";
import { Client } from "@features/customer/type";
import { areAllFieldsEmpty } from "@features/utils/forms-check";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  PlusCircleIcon,
} from "@heroicons/react/outline";
import { useState } from "react";
import toast from "react-hot-toast";
import { Base, InfoSmall } from "@atoms/text";
import { debounce } from "@features/utils/debounce";
import { useTranslation } from "react-i18next";

const client: Column<Client> = {
  render: (item) => (
    <div className={"flex flex-col lg:gap-2"}>
      <Base
        className="whitespace-nowrap text-ellipsis overflow-hidden"
        data-tooltip
      >
        {[item["nomCli"], item["prnomCli"]].filter((a) => a.trim()).join(" ")}
      </Base>

      <InfoSmall
        className="whitespace-nowrap text-ellipsis overflow-hidden"
        data-tooltip
      >
        {[item["addrMail"], item["mobil"]].filter((a) => a.trim()).join(", ")}
      </InfoSmall>

      <InfoSmall
        className="whitespace-nowrap text-ellipsis overflow-hidden"
        data-tooltip
      >
        {[item["addrVille"] || "", item["addrCp"] || ""]
          .filter((a) => a.trim())
          .join(", ")}
      </InfoSmall>
    </div>
  ),
};

export const ClientSelection = (props: {
  client: Client;
  defined: boolean;
  readonly?: boolean;
  createDisabled?: boolean;
  onChange: (value: Client) => void;
}) => {
  const [createClient, setCreateClient] = useState(false);
  const [search, setSearch] = useState({ query: "" });
  const [searchResult, setSearchResult] = useState<Client[] | null>(null);
  const { searchCustomer, createCustomer } = useCustomer();
  const { t } = useTranslation();

  return (
    <div className="grow flex flex-col ">
      {(props.defined || createClient) && (
        <div className="grow flex justify-start">
          <Button
            theme="secondary"
            className="mb-6"
            onClick={() => {
              props.onChange({
                numCli: null,
                mobil: "",
                addrMail: "",
                nomCli: "",
                prnomCli: "",
              });
              setCreateClient(false);
            }}
            icon={(p) => <ArrowLeftIcon {...p} />}
          >
            {t("client.Selection dun autre client")}
          </Button>
        </div>
      )}
      {!props.defined && !createClient && (
        <div className="mb-4">
          <SearchForm
            onChange={async (t) => {
              debounce(async () => setSearchResult(await searchCustomer(t)));
              setSearch(t as any as { query: string });
            }}
            onSearch={async () => setSearchResult(await searchCustomer(search))}
            value={search}
            fields={[
              {
                key: "query",
                type: "text",
                placeholder: "Chercher un client",
              },
            ]}
          />
        </div>
      )}

      {!props.defined &&
        !props.createDisabled &&
        !createClient &&
        !props.readonly && (
          <div>
            <Button
              className="mb-4 w-full"
              theme="outlined"
              size="lg"
              onClick={() => {
                setCreateClient(true);
                setSearchResult(null);
              }}
              icon={(p) => <PlusCircleIcon {...p} />}
            >
              Nouveau client
            </Button>
          </div>
        )}

      {(props.defined || createClient) && (
        <Form
          readonly={props.readonly}
          onChange={(v) => {
            props.onChange(v as Client);
          }}
          value={props.client}
          fields={[
            {
              key: "nomCli",
              label: "Nom",
              type: "text",
              alwaysVisible: true,
            },
            {
              key: "prnomCli",
              label: "Prénom",
              type: "text",
              alwaysVisible: true,
            },
            {
              key: "addrMail",
              label: "Mail",
              type: props.readonly ? "formatted" : "text",
              format: "mail",
              alwaysVisible: true,
            },
            {
              key: "mobil",
              label: "Téléphone",
              type: props.readonly ? "formatted" : "text",
              alwaysVisible: true,
              format: "phone",
            },
            {
              key: "addrCp",
              label: "Code postal",
              type: "text",
              alwaysVisible: true,
            },
            {
              key: "addrVille",
              label: "Ville",
              type: "text",
              alwaysVisible: true,
            },
          ]}
        ></Form>
      )}

      {searchResult && search.query !== "" && (
        <Table
          emptyTabText={search.query === "" ? " " : undefined}
          showPagination={false}
          onClick={(l) => {
            props.onChange(l as Client);
            setCreateClient(true);
            setSearchResult(null);
          }}
          data={searchResult}
          columns={[client]}
        />
      )}

      <div className="grow flex justify-center">
        {createClient && !props.defined && (
          <Button
            disabled={areAllFieldsEmpty(props.client)}
            className="my-6"
            onClick={async () => {
              const res = await createCustomer(props.client);
              if (res.numCli) {
                props.onChange({ ...props.client, numCli: res.numCli });
                toast.success(
                  "Création du nouveau client effectuée avec succés"
                );
              } else {
                toast.error(
                  "Ce compte client existe déjà: " + (res as any).title
                );
                let s = await searchCustomer({
                  query: Object.values(props.client).join(" "),
                });
                if (s.length > 0) {
                  setSearchResult(s);
                }
              }
            }}
            icon={(p) => <ArrowRightIcon {...p} />}
          >
            Créer client
          </Button>
        )}
      </div>
    </div>
  );
};
