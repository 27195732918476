import { Button } from "@atoms/button/button";
import { Modal, ModalContent } from "@atoms/modal/modal";
import { Base, Title } from "@atoms/text";
import { useAuth } from "@features/general/auth/state/use-auth";
import { useEffect, useState } from "react";

import { atom, useRecoilState } from "recoil";

export const InactivModalAtom = atom({
  key: "InactivModalAtom",
  default: false,
});

export const InactivModal = () => {
  const [inactivModal, setInactivModal] = useRecoilState(InactivModalAtom);
  const [seconds, setSeconds] = useState(900);
  const { refreshToken, logout } = useAuth();

  useEffect(() => {
    if (seconds > 0 && inactivModal) {
      const timerId = setTimeout(() => {
        if (seconds - 1 <= 0) {
          setInactivModal(false);
          logout();
        }
        setSeconds(seconds - 1);
      }, 1000);

      return () => clearTimeout(timerId);
    }
  }, [seconds, inactivModal, logout, setInactivModal]);

  useEffect(() => setSeconds(900), [inactivModal, setSeconds]);

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`;
  };

  return (
    <>
      <Modal
        open={inactivModal}
        onClose={() => setInactivModal(false)}
        className="sm:max-w-[500px]"
      >
        <ModalContent title={"Période d'inactitivité prolongée"}>
          <div className="w-full h-full flex flex-col gap-2">
            <Title className="w-full text-center">{formatTime(seconds)}</Title>
            <Base>
              Vous êtes sur le point d'être déconnecté. Pour éviter cela,
              cliquer sur le bouton ci dessous.
            </Base>
            <Button
              onClick={async () => {
                setInactivModal(false);
                await refreshToken();
              }}
            >
              Prolonger ma session
            </Button>
          </div>
        </ModalContent>
      </Modal>
    </>
  );
};
