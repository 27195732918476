import { ReactNode, useCallback, useEffect, useRef, useState } from "react";
import { Grid, Table } from ".";
import { Column, Pagination, RenderOptions } from "./table";

type PropsType<T> = {
  name?: string;
  anchorPos?: number;
  columns: Column<T>[];
  data: T[];
  scrollable?: boolean;
  rowIndex?: string;
  total?: number;
  grid?: boolean;
  cellClassName?: (row: T) => string;
  gridClassName?: string;
  className?: string;
  loading?: boolean;
  onRequestData?: (pagination: Pagination) => Promise<void>;
  onFetchExportData?: (pagination: Pagination) => Promise<any[]>;
  exportDefaultHeader?: string[];
  subObjectHeader?: { [key: string]: string[] };
  moreButtonActiv?: boolean;
  headerPagination?: boolean;
  forceGridResponsive?: boolean;
  customChangeOrder?: (pagination: Pagination) => Promise<void>;

  onClick?: (item: T) => void;
  onSelect?:
    | {
        icon?: (props: any) => JSX.Element;
        label: string | ReactNode;
        callback: (items: T[]) => void;
      }[]
    | ((items: T[]) => void);
  showPagination?: false | "simple" | "full" | true;
  emptyTabText?: string;
  initialPagination?: Pick<
    Pagination,
    "order" | "orderBy" | "page" | "perPage"
  >;
  switchMode?: boolean;
  onChangeMode?: (v: boolean) => void;
  onResetPagination?: () => void; // Nouvelle prop pour réinitialiser la pagination
};

export function TableGridSwitch<T>(
  props: Omit<PropsType<T>, "grid" | "columns"> & {
    gridMode: boolean;
    tableColumns: Column<T>[];
    renderGrid: (item: T, options?: RenderOptions) => string | ReactNode;
    orderables?: string[];
  }
) {
  const [gridOn, setGridOn] = useState(props.gridMode);
  const parentRef = useRef<HTMLDivElement>(null);
  const [parentWidth, setParentWidth] = useState(0);
  const responsiveMode = parentWidth < 600; //Work in progress  for responsive mode

  const resizeEvent = useCallback(() => {
    const { offsetWidth } = parentRef.current!.parentNode! as any;
    setParentWidth(offsetWidth);
  }, [setParentWidth]);

  useEffect(() => {
    window.addEventListener("resize", resizeEvent);
    resizeEvent();
    return () => window.removeEventListener("resize", resizeEvent);
  }, [resizeEvent]);

  return (
    <div ref={parentRef} className="grow flex flex-col gap-1">
      {/* <div className="w-full flex justify-end">
        <Button
          className="opacity-60"
          theme="secondary"
          icon={({ className }) => <TableIcon className={className} />}
          onClick={() => setGridOn(!gridOn)}
        />
      </div> */}
      {gridOn || responsiveMode ? (
        <Grid
          name={props.name}
          moreButtonActiv={props.moreButtonActiv}
          anchorPos={props.anchorPos}
          className="overflow-hidden"
          showPagination={props.showPagination}
          onClick={props.onClick}
          scrollable={true}
          render={(item) => props.renderGrid(item)}
          initialPagination={props.initialPagination}
          onRequestData={props.onRequestData}
          total={props.total}
          loading={props.loading}
          data={props.data}
          onFetchExportData={props.onFetchExportData}
          exportDefaultHeader={props.exportDefaultHeader}
          subObjectHeader={props.subObjectHeader}
          orderables={props.orderables}
          switchMode={true}
          cellClassName={props.cellClassName}
          onChangeMode={() => {
            setGridOn(!gridOn);
            props.onChangeMode && props.onChangeMode(!gridOn);
          }}
          gridClassName={props.gridClassName}
          onResetPagination={props.onResetPagination}
          emptyTabText={props.emptyTabText}
          headerPagination={props.headerPagination}
        />
      ) : (
        <Table
          name={props.name}
          anchorPos={props.anchorPos}
          moreButtonActiv={props.moreButtonActiv}
          className="overflow-hidden"
          showPagination={props.showPagination}
          onClick={props.onClick}
          loading={props.loading}
          scrollable={props.scrollable}
          initialPagination={props.initialPagination}
          onRequestData={props.onRequestData}
          total={props.total}
          data={props.data}
          columns={props.tableColumns}
          onFetchExportData={props.onFetchExportData}
          exportDefaultHeader={props.exportDefaultHeader}
          subObjectHeader={props.subObjectHeader}
          cellClassName={props.cellClassName}
          switchMode={true}
          onChangeMode={() => {
            setGridOn(!gridOn);
            props.onChangeMode && props.onChangeMode(!gridOn);
          }}
          orderables={props.orderables}
          gridClassName={props.gridClassName}
          onResetPagination={props.onResetPagination}
          emptyTabText={props.emptyTabText}
          headerPagination={props.headerPagination}
          customChangeOrder={props.customChangeOrder}
        />
      )}
    </div>
  );
}
