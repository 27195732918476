// store/lastRequestTimeStore.ts
type Listener = (time: number) => void;
let lastRequestTime = 0;
let listeners: Listener[] = [];

export const updateLastRequestTime = (): void => {
  lastRequestTime = Date.now();
  listeners.forEach((listener) => listener(lastRequestTime));
};

export const subscribeToLastRequestTime = (
  listener: Listener
): (() => void) => {
  listeners.push(listener);
  return () => {
    listeners = listeners.filter((l) => l !== listener);
  };
};

export const getLastRequestTime = (): number => lastRequestTime;
