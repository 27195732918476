import React, {
  createContext,
  useState,
  useEffect,
  ReactNode,
  useRef,
} from "react";
//import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";

interface ScanContextType {
  scanData: string | null;
  saveFocused: string | null;
  setSaveFocused?: (focus: string | null) => void;
}

export const ScanContext = createContext<ScanContextType>({
  scanData: null,
  saveFocused: null,
  setSaveFocused: (v) => v,
});

interface ScanProviderProps {
  children: ReactNode;
}

export const ScanProvider: React.FC<ScanProviderProps> = ({ children }) => {
  const [scanData, setScanData] = useState<string | null>(null);
  const [saveFocused, setSaveFocused] = useState<string | null>(null);
  const location = useLocation();

  const bufferRef = useRef("");
  const lastKeyPressTimeRef = useRef(0);
  const finishScanTimeoutIdRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (scanData && scanData.length > 8 && /^\d+$/.test(scanData)) {
      const activEl = document.activeElement as HTMLInputElement;
      if (activEl && !activEl.classList.contains("to-focus")) {
        activEl.blur();
        const toFocusInput = document.querySelector(
          ".to-focus"
        ) as HTMLInputElement;
        if (toFocusInput) toFocusInput.focus();
      }
    }
  }, [scanData]);

  useEffect(() => {
    const handleFocus = (event: FocusEvent) => {
      const target = event.target as HTMLInputElement;
      setSaveFocused(target.value);
    };

    const handleBlur = (event: FocusEvent) => {
      setSaveFocused(null);
    };

    const inputs = document.querySelectorAll("input");
    inputs.forEach((input) => {
      input.addEventListener("focus", handleFocus);
      input.addEventListener("blur", handleBlur);
    });

    return () => {
      inputs.forEach((input) => {
        input.removeEventListener("focus", handleFocus);
        input.removeEventListener("blur", handleBlur);
      });
    };
  }, [location]);

  useEffect(() => {
    const flushBuffer = () => {
      if (bufferRef.current.length > 8 && /^\d+$/.test(bufferRef.current)) {
        setScanData(bufferRef.current);
        bufferRef.current = "";
        setTimeout(() => setScanData(null), 1000);
      }
    };

    const keyPressHandler = (e: KeyboardEvent) => {
      // console.log(bufferRef);
      // toast.success(bufferRef.current);
      if (e.key === "Enter") {
        flushBuffer();
      } else {
        clearTimeout(finishScanTimeoutIdRef.current as NodeJS.Timeout);
        finishScanTimeoutIdRef.current = setTimeout(flushBuffer, 500);
      }
      const currentTime = Date.now();
      if (currentTime - lastKeyPressTimeRef.current > 500) {
        bufferRef.current = "";
      }

      bufferRef.current += e.key;
      lastKeyPressTimeRef.current = currentTime;
    };

    document.addEventListener("keypress", keyPressHandler);

    return () => {
      document.removeEventListener("keypress", keyPressHandler);
      if (finishScanTimeoutIdRef.current)
        clearTimeout(finishScanTimeoutIdRef.current);
    };
  }, []);

  return (
    <ScanContext.Provider value={{ scanData, saveFocused, setSaveFocused }}>
      {children}
    </ScanContext.Provider>
  );
};

export default ScanProvider;
