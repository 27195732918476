import { AuthState } from "@features/general/auth/state/store";
import { ROUTES } from "@features/routes";
import {
  flushGlobalEffects,
  useGlobalEffect,
} from "@features/utils/hooks/use-global-effect";
import {
  clearSessionStorage,
  createSession,
} from "@features/utils/local-storage";
import { LoadingState } from "@features/utils/store/loading-state-atom";
import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilCallback, useRecoilState } from "recoil";
import { AuthApiClient } from "../api-client/auth-api-client";
import { AuthJWT } from "../jwt";
import { AuthType } from "../types";
import { differenceInMinutes } from "date-fns";
import Env from "@config/environment";
import { getLastRequestTime } from "@features/utils/utils-global-state";

let autoRefreshTokenInterval: any = null;

export const useAuth = () => {
  const [auth, setAuth] = useRecoilState(AuthState);
  const [loading, setLoading] = useRecoilState(LoadingState(["useAuth", true]));
  const [lastRequestTime] = useState<number>(getLastRequestTime());

  const navigate = useNavigate();

  const autoRefreshToken = (expireAt: number) => {
    expireAt = Math.max(expireAt, Date.now() + 1000 * 60 * 5);
    Env.lastRefresh = Date.now();
    if (autoRefreshTokenInterval) clearInterval(autoRefreshTokenInterval);
    autoRefreshTokenInterval = setInterval(async () => {
      if (
        differenceInMinutes(Date.now(), lastRequestTime) < Env.maxInactiv &&
        differenceInMinutes(Date.now(), Env.lastRefresh) >= 20
      ) {
        refreshToken();
      }
    }, 1000 * 60 * 1);
  };

  const refreshToken = async () => {
    try {
      const {
        refreshToken: newRefreshToken,
        token,
        tokenExpiryTime,
      } = await AuthApiClient.refresh();

      if (token === null || token === undefined) {
        throw new Error("Token is null");
      }

      AuthJWT.token = token;
      AuthJWT.tokenExpiryTime = tokenExpiryTime;
      AuthJWT.refresh = newRefreshToken;
      autoRefreshToken(new Date(tokenExpiryTime).getTime());

      await getUser();
      return true;
    } catch (e) {
      logout();
    }

    return false;
  };

  const checkId = async (userId: string) => {
    try {
      return await AuthApiClient.checkId(userId);
    } catch (e) {
      return {
        exist: false,
        actif: false,
      };
    }
  };

  const login = async (email: string, password: string) => {
    try {
      let { token } = await AuthApiClient.login(email, password);

      if (token === null || token === undefined) {
        logout();
        return false;
      }
      AuthJWT.token = token.token;
      AuthJWT.refresh = token.refreshToken;
      AuthJWT.tokenExpiryTime = token.tokenExpiryTime;
      autoRefreshToken(new Date(token.refreshTokenExpiryTime).getTime());

      await getUser();
      createSession();
    } catch (e) {
      throw new Error((e as Error).message);
    }

    return true;
  };

  const getUser = async () => {
    const user = await AuthApiClient.getUser();
    AuthJWT.userId = user.id;

    setAuth({
      ...auth,
      isLoggedIn: true,
      authorization: AuthJWT.token || "",
      authorizationRefresh: AuthJWT.refresh || "",
      user: user,
    });

    return user;
  };

  const logout = useRecoilCallback(
    ({ snapshot }) =>
      () => {
        if (AuthJWT.token) AuthApiClient.logout();
        AuthJWT.empty();
        const updated = {
          ...snapshot.getLoadable(AuthState).contents,
          isLoggedIn: false,
          authorization: null,
          authorizationRefresh: null,
          user: null,
        };
        setAuth(updated);
        persistAuth(updated);
        flushGlobalEffects();

        const urlParams = new URLSearchParams(window.location.search);
        const redirect = urlParams.get("r");
        const pathname = window.location.pathname;
        clearInterval(autoRefreshTokenInterval);
        clearSessionStorage();
        navigate(
          ROUTES.Login +
            "?r=" +
            encodeURIComponent(
              redirect ||
                (pathname.startsWith("/login") ? ROUTES.Home : pathname)
            )
        );
      },
    []
  );

  const persistAuth = useCallback(
    (updated?: AuthType) => {
      localStorage.setItem(
        "user.access_token",
        JSON.stringify(updated ? updated?.authorization : auth?.authorization)
      );
      localStorage.setItem(
        "user.refresh_token",
        JSON.stringify(
          updated ? updated?.authorizationRefresh : auth?.authorizationRefresh
        )
      );
      localStorage.setItem(
        "user.profile",
        JSON.stringify(updated ? updated?.user : auth?.user)
      );
    },

    [auth?.user, auth?.authorizationRefresh, auth?.authorization]
  );

  useGlobalEffect(
    "useAuth",
    () => {
      setLoading(true);
    },
    []
  );

  useEffect(() => {
    if (auth.isLoggedIn) setLoading(false);
  }, [auth.isLoggedIn, setLoading]);

  useGlobalEffect(
    "useAuthLogin",
    () => {
      if (!auth.isLoggedIn) {
        if (auth.authorization) {
          AuthJWT.token = auth.authorization;
          AuthJWT.refresh = auth.authorizationRefresh;
          refreshToken().then(() => {
            setLoading(false);
          });
        } else {
          logout();
          setLoading(false);
        }
        return () => {};
      }

      persistAuth();
      setLoading(false);
    },
    [auth.isLoggedIn]
  );

  return {
    loading,
    user: _.cloneDeep(auth.user),
    loggedIn: auth.isLoggedIn,
    logout,
    login,
    checkId,
    refresh: getUser,
    refreshToken,
  };
};
