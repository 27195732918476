import Link from "@atoms/link";
import { Base } from "@atoms/text";
import { stringToColor } from "@features/utils/format/strings";
import { ReactNode } from "react";

export default function ModuleBox(props: {
  name?: string;
  route?: string;
  logo: ReactNode;
  color?: string;
  disabled?: boolean;
}) {
  return (
    <Link
      to={props.route}
      className="relative flex flex-col cursor-pointer group"
    >
      {props.disabled && (
        <div className="absolute inset-0 bg-gray-500 bg-opacity-70 z-10" />
      )}
      <div
        className="shadow-md text-center flex justify-center items-center w-32 h-32 group-hover:opacity-75 text-white"
        style={{
          backgroundColor: props.color || stringToColor(props.name || ""),
        }}
      >
        {props.logo}
      </div>
      <div className="h-10 flex items-center justify-center shrink-0">
        <Base>{props.name}</Base>
      </div>
    </Link>
  );
}
