import { Button } from "@atoms/button/button";
import { Frame } from "@atoms/layout/frame";
import { Page } from "@atoms/layout/page";
import Tabs from "@atoms/tabs";
import { BaseSmall, Title } from "@atoms/text";
import { ReceptionCard } from "@components/reception/reception-cards";
import { useReception } from "@features/reception/state/use-reception";
import { ReceptionUnit } from "@features/reception/types";
import { useControlledEffect } from "@features/utils/hooks/use-controlled-effect";
import { Grid } from "@molecules/table";
import { useState } from "react";
import { useParams } from "react-router-dom";

export const SupplierPage = () => {
  /*  const { id } = useParams<{ id: string }>();
  if (loading) {
    return <PageLoader />;
  }

  if (!originalProduct) {
    return <NotAvailablePage />;
  } */

  //const setRapatriementModal = useSetRecoilState(RapatriementModalAtom);

  const [tabIndex, setTabIndex] = useState(0);
  const { refreshAllOngoingReceipt, allOngoingReceipt } = useReception();
  const { codeFourn } = useParams<{
    codeFourn: string;
    raisSocial: string;
  }>();

  useControlledEffect(() => {
    refreshAllOngoingReceipt(undefined, { trait: false, codeFourn: codeFourn });
  }, [codeFourn]);

  useControlledEffect(() => {
    const timer = setTimeout(() => {
      document.title = `Inférence - ${codeFourn}`;
    }, 100);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const chooseCard = (item: ReceptionUnit) => {
    switch (item.orig) {
      case "0":
        return <ReceptionCard item={item} />;
      // case 1:
      //   return <BLCard item={item} />;
      // case 2:
      //   return <CommandCard item={item} />;
      default:
        return <ReceptionCard item={item} />;
    }
  };

  const chooseData = (tabNumber: number) => {
    switch (tabNumber) {
      case 0:
        return allOngoingReceipt.items;
      // case 1:
      //   return <BLCard item={item} />;
      // case 2:
      //   return <CommandCard item={item} />;
      default:
        return [];
    }
  };
  return (
    <Page>
      <Title>{codeFourn}</Title>
      <div className="flex flex-col-reverse md:flex-row gap-4 mt-6 justify-between ">
        <div className="w-1/2 flex flex-col mt-6 justify-end">
          <Tabs
            tabs={[
              {
                value: 0,
                label: `Réceptions en cours (${allOngoingReceipt.pagination.totalRows})`,
              },
              { value: 1, label: "Bons de livraisons (2)" },
              { value: 2, label: "Non clôturées (4)" },
              { value: 3, label: "Attendus (3)" },
            ]}
            value={tabIndex}
            onChange={(v) => {
              setTabIndex(v as number);
            }}
          />{" "}
        </div>

        <div className="flex gap-2 mt-6 justify-end">
          <Frame className="flex flex-col gap-2 max-w-xs">
            <BaseSmall className="text-center">
              Les commandes du fournisseur seront dynamiquement associées aux
              articles
            </BaseSmall>
            <Button size="sm">Créer réception sur portefeuille</Button>
          </Frame>
          <Frame className="flex flex-col gap-2 justify-between">
            <BaseSmall className="text-center">
              Les commandes du fournisseur seront ignorées.
            </BaseSmall>
            <Button size="sm">Créer réception hors commande</Button>
          </Frame>
        </div>
      </div>
      <div className="h-full w-full flex flex-col gap-2 my-12">
        <Grid
          className="grow"
          data={chooseData(tabIndex)}
          render={(el) => chooseCard(el)}
        />
      </div>
    </Page>
  );
};
