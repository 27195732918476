import { Shortcut, useShortcuts } from "@features/utils/shortcuts";
import { EyeIcon, EyeOffIcon } from "@heroicons/react/outline";
import _ from "lodash";
import React, { useState } from "react";

export interface InputProps
  extends Omit<
    React.InputHTMLAttributes<HTMLInputElement> &
      React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    "size"
  > {
  highlight?: boolean;
  theme?: "plain";
  label?: string;
  size?: "sm" | "md" | "lg";
  feedback?: string;
  hasError?: boolean;
  multiline?: boolean;
  inputComponent?: React.ReactNode;
  inputClassName?: string;
  className?: string;
  inputRef?: React.Ref<HTMLInputElement | HTMLTextAreaElement>;
  shortcut?: Shortcut[];
}

export const defaultInputClassName = (theme: "plain" = "plain") => {
  return "shadow-sm focus:ring-blue-600 focus:border-blue-600 block w-full text-sm border-gray-200 dark:bg-slate-900 dark:border-slate-700 dark:text-white ";
};

export const errorInputClassName = (theme: "plain" = "plain") => {
  return (
    defaultInputClassName(theme) +
    " bg-red-50 border-red-300 dark:bg-red-900 dark:border-red-800"
  );
};

export const Input = (props: InputProps) => {
  let inputClassName = props.hasError
    ? errorInputClassName(props.theme)
    : defaultInputClassName(props.theme);
  inputClassName = inputClassName + (props.disabled ? " opacity-25" : "");

  const internalRef = React.useRef<HTMLInputElement | HTMLTextAreaElement>();
  const inputRef = props.inputRef || internalRef;

  if (props.highlight && props.value)
    inputClassName = inputClassName + " !ring-2 !ring-yellow-500";

  if (!props.multiline) {
    if (props.size === "lg") inputClassName = inputClassName + " h-11";
    else if (props.size === "sm") inputClassName = inputClassName + " h-7";
    else inputClassName = inputClassName + " h-9";
  }

  const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  useShortcuts(
    !props.disabled && props.shortcut?.length ? [...props.shortcut] : [],
    (e) => {
      (inputRef as any)?.current?.focus();
    }
  );

  return (
    <>
      {props.inputComponent ||
        (props.multiline ? (
          <textarea
            ref={inputRef as React.Ref<HTMLTextAreaElement>}
            className={
              inputClassName +
              " " +
              props.inputClassName +
              " " +
              props.className
            }
            {..._.omit(
              props as any,
              "label",
              "inputRef",
              "inputClassName",
              "className",
              "value",
              "size",
              "multiline",
              "highlight"
            )}
            value={props.value}
          />
        ) : props.type === "password" ? (
          <div className="relative">
            <input
              type={passwordVisible ? "text" : "password"}
              className={
                inputClassName +
                " " +
                props.inputClassName +
                " " +
                props.className
              }
              {..._.omit(
                props,
                "label",
                "inputRef",
                "inputClassName",
                "className",
                "size",
                "highlight",
                "type"
              )}
            />
            {passwordVisible ? (
              <EyeOffIcon
                className="h-5 w-5 text-slate-500 opacity-80 absolute top-1/2 right-3 transform -translate-y-1/2 cursor-pointer"
                onClick={() => {
                  togglePasswordVisibility();
                }}
              />
            ) : (
              <EyeIcon
                className="h-5 w-5 text-slate-500 opacity-80 absolute top-1/2 right-3 transform -translate-y-1/2 cursor-pointer"
                onClick={() => {
                  togglePasswordVisibility();
                }}
              />
            )}
          </div>
        ) : (
          <input
            ref={inputRef as React.Ref<HTMLInputElement>}
            type="text"
            className={
              inputClassName +
              " " +
              props.inputClassName +
              " " +
              props.className
            }
            {..._.omit(
              props,
              "label",
              "inputRef",
              "inputClassName",
              "className",
              "size",
              "highlight"
            )}
          />
        ))}
    </>
  );
};
