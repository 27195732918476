import { fetchServer } from "@features/utils/fetch-server";
import { AuthJwtType, UserType } from "../types";
import { AuthJWT } from "../jwt";

export class AuthApiClient {
  static getUser = async () => {
    const response = await fetchServer("/user", {
      method: "POST",
      body: JSON.stringify({ refreshToken: AuthJWT.refresh }),
    });
    const data = await response.json();
    return data as UserType;
  };

  static login = async (email: string, password: string) => {
    const dataToSend = {
      refer: email,
      pwd: password,
    };

    const response = await fetchServer("/user/authenticate", {
      method: "POST",
      body: JSON.stringify(dataToSend),
      reloadOn401: false,
    });
    if (response.status !== 200) {
      throw new Error(response.status.toString());
    }
    const data = await response.json();

    return data as UserType;
  };

  static checkId = async (userId: string) => {
    const response = await fetchServer(`/user/${userId}`);
    const data = await response.json();

    return data as {
      exist: boolean;
      actif: boolean;
    };
  };

  static refresh = async () => {
    const response = await fetchServer("/user/authenticate/refresh", {
      method: "POST",
      body: JSON.stringify({ refreshToken: AuthJWT.refresh }),
    });
    const data = await response.json();
    return data as AuthJwtType;
  };

  static logout = async () => {
    await fetchServer("/user/authenticate/logout", {
      method: "POST",
      body: JSON.stringify({ refreshToken: AuthJWT.refresh }),
    });
  };
}
