import { useRecoilState } from "recoil";
import { SupplierApiClient } from "../api-client/api-client";
import { CommandType, ReturnType } from "../types";
import { LoadingState } from "@features/utils/store/loading-state-atom";

export const useSuppliers = () => {
  const [loading, setLoading] = useRecoilState(
    LoadingState(["useOrder", false])
  );
  const getMotif = async () => {
    return await SupplierApiClient.getMotifRefus();
  };

  const getModesExpedition = async () => {
    return await SupplierApiClient.getModesExpedition();
  };

  const createRetour = async (retour: ReturnType, codeLieu: string) => {
    setLoading(true);
    const res = await SupplierApiClient.createRetour(retour, codeLieu);
    setLoading(false);
    return res;
  };

  const createOrder = async (order: CommandType, codeLieu: string) => {
    setLoading(true);
    const res = await SupplierApiClient.createOrder(order, codeLieu);
    setLoading(false);
    return res;
  };

  return { loading, getMotif, getModesExpedition, createRetour, createOrder };
};
