import { useState } from "react";
import { CustomerAPIClient } from "../api-client/api-client";
import { Client, Order } from "../type";
import { useRecoilState } from "recoil";
import { SelectedCustomer } from "./store";
import { CommonPagination } from "@features/types";

export const useCustomer = () => {
  const [userHistory, setUserHistory] = useState<Order[]>();
  const [historyPagination, setHistoryPagination] =
    useState<CommonPagination>();
  const [selectedCustomer, setSelectedCustomer] =
    useRecoilState(SelectedCustomer);

  const searchCustomer = async (search: any) => {
    if (search.query.trim()) {
      return (await CustomerAPIClient.searchCustomer(search, "CPT")).filter(
        (el) => el.prnomCli !== "" || el.nomCli || "" || el.addrMail !== ""
      ); //Filtre temporaire pour renvoyer que des cartouches avec des infos --> plus joli pour la présentation
    } else {
      return [];
    }
  };

  const getCustomerSuggestion = async (search: { query: string }) => {
    if (search.query.trim()) {
      return (
        await CustomerAPIClient.getClientSuggestion(search.query, "CPT")
      ).filter(
        (el) => el.prnomCli !== "" || el.nomCli || "" || el.addrMail !== ""
      ); //Filtre temporaire pour renvoyer que des cartouches avec des infos --> plus joli pour la présentation
    } else {
      return null;
    }
  };

  const createCustomer = async (infos: Client) => {
    return await CustomerAPIClient.createCustomer(infos);
  };

  const getCustomerStatus = async (clientId: string) => {
    return await CustomerAPIClient.getCustomerStatus(clientId);
  };

  const getCustomerOrdersHistory = async (
    clientID: string,
    pageNumber: number = 1,
    pageSize: number = 20
  ) => {
    const { pagination, items } = await CustomerAPIClient.getUserCommandHistory(
      clientID,
      pageNumber,
      pageSize
    );
    setUserHistory(items);
    setHistoryPagination(pagination);
  };

  return {
    searchCustomer,
    getCustomerSuggestion,
    createCustomer,
    getCustomerStatus,
    getCustomerOrdersHistory,
    userHistory,
    historyPagination,
    selectedCustomer,
    setSelectedCustomer,
  };
};
